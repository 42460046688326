var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        title: `${_vm.FormMSG(14, "Informations")}`,
        size: "xl",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "section",
                { staticClass: "d-flex flex-row align-items-center w-100" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-start fs-14 w-25 gap-1",
                    },
                    [
                      _c("div", { staticClass: "status-header" }, [
                        _c(
                          "div",
                          [
                            _c(
                              _vm.getLucideIcon(_vm.ICONS.ALERT_TRIANGLE.name),
                              {
                                tag: "component",
                                attrs: { color: "#ebe78a", size: 20 },
                              }
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.FormMSG(15, "Warning"))),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end fs-14 w-75",
                    },
                    [
                      _c("span", { staticClass: "border-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              16,
                              "These expense are not sent, the flags or categories are not complete"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "light" },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.FormMSG(2, "Close")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-center justify-content-center w-100 table-content",
          attrs: { id: "table" },
        },
        [
          _c("div", { staticClass: "d-flex flex-row w-100 row" }, [
            _c("div", { staticClass: "d-flex w-12" }, [
              _vm._v(_vm._s(_vm.FormMSG(6, "Date"))),
            ]),
            _c("div", { staticClass: "d-flex w-6" }, [
              _vm._v(_vm._s(_vm.FormMSG(7, "Id"))),
            ]),
            _c("div", { staticClass: "d-flex w-43" }, [
              _vm._v(_vm._s(_vm.FormMSG(8, "Description"))),
            ]),
            _c("div", { staticClass: "d-flex w-10" }, [
              _vm._v(_vm._s(_vm.FormMSG(9, "Type"))),
            ]),
            _c("div", { staticClass: "d-flex w-12" }, [
              _vm._v(_vm._s(_vm.FormMSG(11, "Status"))),
            ]),
            _c("div", { staticClass: "d-flex w-10" }, [
              _vm._v(_vm._s(_vm.FormMSG(12, "Total amount"))),
            ]),
            _c("div", { staticClass: "d-flex w-7" }, [
              _vm._v(_vm._s(_vm.FormMSG(13, "CO2"))),
            ]),
          ]),
          _vm._l(_vm.mapItem, function (_item, index) {
            return _c(
              "div",
              { key: index, staticClass: "d-flex flex-row w-100 cell" },
              [
                _c(
                  "section",
                  { staticClass: "d-flex flex-column w-100 gap-4" },
                  [
                    _c("section", { staticClass: "d-flex flex-row w-100" }, [
                      _c("div", { staticClass: "d-flex w-12 border-cell" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row gap-1 align-items-center",
                          },
                          [
                            _c("div", [
                              !_vm.mapActiveShowChildren(_item)
                                ? _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleActiveChild(_item)
                                        },
                                      },
                                    },
                                    [_vm._v("+")]
                                  )
                                : _vm.mapActiveShowChildren(_item)
                                ? _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleActiveChild(_item)
                                        },
                                      },
                                    },
                                    [_vm._v("-")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.dateFormat(_item.date))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex w-6 border-cell" }, [
                        _vm._v(_vm._s(_vm.getIdTemplate(_item.id))),
                      ]),
                      _c("div", { staticClass: "d-flex w-43 border-cell" }, [
                        _vm._v(_vm._s(_vm.getDescription(_item.description))),
                      ]),
                      _c("div", { staticClass: "d-flex w-10 border-cell" }, [
                        _vm._v(_vm._s(_vm.getTypeTemplate(_item))),
                      ]),
                      _c("div", { staticClass: "d-flex w-12 border-cell" }, [
                        _c("div", { staticClass: "wrap-status" }, [
                          _c(
                            "div",
                            {
                              class: `status ${_vm.getStatusColor(
                                _item.validated
                              )}`,
                              staticStyle: { "font-size": "0.7rem" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.getLabelStatus(_item.validated)) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex w-10 border-cell" }, [
                        _vm._v(_vm._s(_vm.getAmountTotal(_item.amountTotal))),
                      ]),
                      _c("div", { staticClass: "d-flex w-7 border-cell" }, [
                        _vm._v(_vm._s(_vm.getKgCoTwo(_item.kgCoTwo))),
                      ]),
                    ]),
                    _vm.mapActiveShowChildren(_item)
                      ? _c(
                          "section",
                          {
                            staticClass:
                              "d-flex flex-column w-100 cell-item-child",
                          },
                          _vm._l(
                            _vm.mapExpenseItem(_item),
                            function (expItem, expIndex) {
                              return _c(
                                "div",
                                {
                                  key: expIndex,
                                  staticClass: "d-flex flex-row w-100",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-end w-id",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(100, "ID")) +
                                          ": " +
                                          _vm._s(expItem.id)
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column ml-4 w-100",
                                    },
                                    [
                                      _c("table", [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            { staticClass: "bg-primary" },
                                            [
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(101, "Name")
                                                  )
                                                ),
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(102, "Short")
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              expItem.missingProjectFlag,
                                              function (mssFlag, mssIndex) {
                                                return _c(
                                                  "tr",
                                                  { key: mssIndex },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          width: "30%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "u",
                                                          {
                                                            staticClass:
                                                              "fw-700",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  104,
                                                                  "Flag"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              " : " +
                                                                mssFlag.name
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          width: "30%",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(mssFlag.short)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            expItem.category === 0
                                              ? _c("tr", [
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "2" } },
                                                    [
                                                      _c(
                                                        "u",
                                                        {
                                                          staticClass: "fw-700",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                103,
                                                                "Category"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            " : " +
                                                              expItem.categoryName
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }