<template>
	<div class="container-layout form px-0">
		<filter-budget-advanced
			:show-user-selector="false"
			:label-expense-details="FormMSG(148, 'Expense details')"
			use-department-for-expense
			:hide-process-filter="false"
			:filter-type="FILTER_TYPE.BUDGET_EXPENSES"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
			hide-co-two-detail
			:hide-flag-filter="false"
		/>
		<div class="row mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch id="expense-search-id" @filtered-users="activeFilter" @handle-init-filter="onHandleInitFilter" />
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-perpage="perPage"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="FILTER_TYPE.BUDGET_EXPENSES"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</div>
		<!-- <pre>{{ expenses }}</pre> -->
		<div class="row mt-3 ml-1">
			<CardListBuilder class="hide-on-desktop" v-if="!$screen.md" :items="expenseItems" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="data">
					<div class="mt-2">
						<div @click="openEditExpense(data.item)" class="edit" role="button">
							<edit />
						</div>
						&nbsp;
						<div v-if="data.item.images.length > 0" @click="showPicture(data.item.images)" class="pic" role="button">
							<photo />
						</div>
					</div>
				</template>
			</CardListBuilder>
			<b-table
				responsive="sm"
				:filter="filter"
				v-else
				:items="expenseItems"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				selectable
				:selectedVariant="selectedColor"
				:select-mode="selectMode"
				:empty-text="FormMSG(265, 'No budget expenses found')"
			>
				<!-- <template #head(processed)="">
					<div class="text-center w-100 p-1" style="margin-left: 3px" id="processed-all">
						<b-form-checkbox :disabled="!canMarkEntryAsProcessed" v-model="selectAll" size="lg" @change="handleInputSelectAll">
							<div class="mt-1" style="color: #fff">{{ FormMSG(521, 'Processed') }}</div>
						</b-form-checkbox>
					</div>
				</template> -->
				<template #cell(processed)="{ item, index }">
					<div>
						<b-form-checkbox
							v-model="item.processed"
							:disabled="!canMarkEntryAsProcessed"
							size="lg"
							:value="true"
							:unchecked-value="false"
							@change="handleChangeProcess($event, item, index)"
						/>
					</div>
				</template>
				<template #cell(validated)="data">
					<div class="wrap-status">
						<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
							{{ data.item.validatedStatus }}
						</div>
					</div>
				</template>
				<template #cell(userDepartmentName)="data">
					<div class="text-left">
						<div>
							{{ data.item.userDepartmentName.charAt(0).toUpperCase() + data.item.userDepartmentName.slice(1).toLowerCase() }} -
							{{ data.item.functionName.charAt(0).toUpperCase() + data.item.functionName.slice(1).toLowerCase() }}
						</div>
					</div>
				</template>
				<template #cell(option)="data">
					<b-dropdown no-caret dropleft offset="25" variant="none" size="sm">
						<template #button-content>
							<more-vertical :size="16" />
						</template>
						<b-dropdown-item @click="openEditExpense(data.item)">
							<!-- <edit :size="16" />  -->
							<div class="text-color-rhapsody-in-blue d-flex align-items-center">
								<component :is="getLucideIcon('Edit')" :size="16" />
								<div class="ml-2">{{ FormMSG(1000, 'Edit') }}</div>
							</div>
						</b-dropdown-item>
						<!-- <b-dropdown-item v-if="data.item.images.length > 0" @click="showPicture(data.item.images)">
							<photo :size="16" /> {{ FormMSG(1001, ' See picture') }}
						</b-dropdown-item> -->
						<b-dropdown-item v-if="canDeleteExpensesOrPO" @click="openDeleteWarning(data.item)">
							<!-- <edit :size="16" />  -->
							<div class="text-danger d-flex align-items-center">
								<component :is="getLucideIcon('Trash')" :size="16" />
								<div class="ml-2">{{ FormMSG(1002, 'Delete') }}</div>
							</div>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
		</div>
		<expense-carousel-view-modal
			ref="expense-carousel-view-modal"
			v-model="isNewExpenseModalOpen"
			:reload-data-after-img-inserted="false"
			:expense="currentExpense"
			:expense-item="{
				index: 0,
				data: currentExpenseItem
			}"
			:expense-items="[currentExpenseItem]"
			can-only-make-split-item
			@expense-carousel-view-modal:closed="onExpenseCarouselViewClosed"
			@expense-carousel-view-modal:reload="onExpenseCarouselViewReloaded"
		/>
		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="expenseitem"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="expenseRerpotOnClosed"
		/>
		<common-warning-on-delete
			v-model="showDeleteWarning"
			:message="messageOnDeleteExpense"
			@dialog:cancel="closeDeleteWarning"
			@dialog:confirm="deleteExpenseItem"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import GlobalMixin from '@/mixins/global.mixin';
import { MoreVertical, Edit, Image as Photo, Search, X, AlertTriangle } from 'lucide-vue';
import moment from 'moment';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import ExpenseService from '@/components/ExpenseService/Main';
// import ExpenseCarouselViewModal from '@/components/ExpenseService/ExpenseCarouselViewModal';
import { getProjectUserExpenseDetail } from '@/cruds/budget.crud';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import { store } from '@/store';
import Treeselect from '@riophae/vue-treeselect';
import CustomPagination from '@/components/Budget/CustomPagination';
import { getFileExtension } from '@/shared/helpers';
import { isNil, formatFileName } from '~utils';
import _ from 'lodash';
import { expenseItemReport } from '@/cruds/export.crud';
import filterInputSearch from '../users/filterInput.vue';
import { getScripts } from '@/cruds/script.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, FILTER_TYPE } from '@/shared/constants';
import { changeStatusOfProcess } from '@/cruds/budget.crud';
import ExpenseCarouselViewModal from '@/components/ExpenseService/ExpenseCarouselViewModal';

// For delete expense Items
import { deleteExpense } from '@/cruds/expense.crud';
import CommonWarningOnDelete from '@/components/Dialogs/CommonWarningOnDeleteDialog';

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'BudgetExpenses',
	components: {
		MoreVertical,
		Photo,
		Edit,
		ExpenseService,
		FilterBudgetAdvanced,
		Search,
		X,
		Treeselect,
		AlertTriangle,
		CustomPagination,
		ScriptExportModal,
		filterInputSearch,
		ExpenseCarouselViewModal,
		CommonWarningOnDelete
	},
	mixins: [languageMessages, GlobalMixin],
	props: {
		initDataForFilterAdvanced: { type: Object, required: true },
		budgetExpensesData: { type: Array, required: true },
		totalRecords: { type: Number, required: 0 }
	},
	data() {
		return {
			visible: false,
			caption1: '',
			hv: 'dark',
			selectMode: 'single',
			selectedColor: 'primary',
			expenseItems: [],
			filter: '',
			openShowRoomPicModal: false,
			haveActiveFilter: false,
			isNewExpenseModalOpen: false,
			newExpenseType: 0,
			currentExpenseItem: null,
			currEditUserId: null,
			filtersSelected: {},
			firstLoad: true,
			perPage: 100,
			offset: 0,
			limit: 100,
			cookiesPaginationBudgetExpenses: 'pagination_budget_expenses',
			isScriptExpenseReportOpen: false,
			scripts: [],
			customReport: null,
			scriptType: 14,
			removeFromReportKeys: [],
			expenses: [],
			currentExpense: null,
			showDeleteWarning: false,
			itemToDelete: null,
			FILTER_TYPE,
			selectAll: false,
			processedIds: []
		};
	},
	watch: {
		budgetExpensesData: {
			handler(value) {
				if (!_.isNil(value)) {
					// launch loading
					this.emitLoading(true);

					let expenseItems = [];
					let expenses = [];
					let tmpSelectProcessedAll = false;
					this.selectAll = false;
					this.processedIds = [];

					for (const item of value) {
						let expenseItem = item.expenseItem;
						let expense = item.expense;

						expenseItem.fullName = expenseItem.user.name + ' ' + expenseItem.user.firstName;
						expenseItem.statusClass = validatedColor(expenseItem.validated);
						expenseItem.validatedStatus = this.validatedText(expenseItem.validated);
						expenseItem.expenseId = item.expense.id;
						expenseItems.push(expenseItem);

						if (expenseItem.processed === true) {
							this.processedIds.push(expenseItem.id.toString());
						}

						if (tmpSelectProcessedAll === false) {
							if (expenseItem.processed === true) {
								this.selectAll = true;
								tmpSelectProcessedAll = true;
							}
						}

						const findIndexOfExpense = expenses.findIndex((expense) => +expense.id === +item.expense.id);

						if (findIndexOfExpense === -1) {
							expense.fullName = expenseItem.fullName;
							expense.departmentName = expenseItem.departmentName;
							expense.functionName = expenseItem.functionName;

							expenses.push(expense);
						}
					}

					this.expenses = expenses;
					this.expenseItems = expenseItems;

					// stop all remaining loading
					this.emitLoading(false);
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		canEditExpense() {
			return !!store.canEditExpense();
		},
		canDeleteExpensesOrPO() {
			return store.canDeletePoExpense();
		},
		canMarkEntryAsProcessed() {
			return store.canMarkEntryAsProcessed();
		},
		messageOnDeleteExpense() {
			return this.FormMSG(
				3333,
				'if you delete this expense item, you will not be able to recover it. This action is irreversible. Are your sure you want to delete this expense item?'
			);
		},
		bgFields() {
			return [
				{
					key: 'processed',
					sortable: false,
					class: 'text-center w-90-px'
				},
				{
					key: 'date',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'type',
					label: this.FormMSG(124, 'Type'),
					formatter: (v) => {
						return this.getLabelTypeExpense(v);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(200, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(211, 'Category'),
					formatter: (v, z, root) => {
						return root.costCenter + ' - ' + root.categoryName.toUpperCase();
					},
					sortable: true,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'userDepartmentName',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'option',
					label: this.FormMSG(126, 'Option'),
					class: 'text-center'
				}
			];
		},
		bgFieldsMobile() {
			return [
				{
					key: 'expenseDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(200, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(211, 'Category'),
					formatter: (v, z, root) => {
						return root.costCenter + ' - ' + root.categoryName.toUpperCase();
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplier',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'department',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'amount',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'co2',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-left',
					sortable: true
				}
			];
		}
	},
	created() {
		this.caption1 = this.FormMSG(1, 'Show filters');
	},
	methods: {
		openDeleteWarning(itemToDetete) {
			this.itemToDelete = itemToDetete.id;
			this.showDeleteWarning = true;
		},
		closeDeleteWarning() {
			this.itemToDelete = null;
			this.showDeleteWarning = false;
		},
		async deleteExpenseItem() {
			if (this.itemToDelete) {
				try {
					await deleteExpense(this.itemToDelete);
				} catch (error) {
					console.log(error);
				} finally {
					this.itemToDelete = null;
					this.showDeleteWarning = false;
					this.createToastForMobile('Success', this.FormMSG(222, 'Expense Item deleted successfully'), '', 'success');
					this.$bus.$emit('filter-budget-advanced:refresh-data');
				}
			}
			this.showDeleteWarning = false;
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		activeFilter(keyword) {
			this.haveActiveFilter = true;
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.haveActiveFilter = false;
			this.$emit('activate-filter-by-keyword', null);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.id, 2, payload);

			if (payload === false) {
				const idx = this.processedIds.findIndex((id) => id === item.id.toString());
				if (idx !== -1) {
					this.processedIds.splice(idx, 1);
				}
			}

			this.selectAll = this.processedIds.length > 0;
		},
		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-expenses:load-more', {
				offset,
				limit,
				perPage
			});
		},
		getLabelTypeExpense(value) {
			let name = '';
			const labelTypes = [
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
			const types = labelTypes.filter((lT) => lT.value === value);
			if (types.length > 0) {
				for (const typeName of types) {
					return (name = typeName.text.toUpperCase());
				}
			}
			return name;
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptExpenseReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};
				}

				let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE_ITEM, script.id, script.fileName);

				await expenseItemReport(payload, script.id)
					.then((result) => {
						this.forceDownload(result, fileName + '.' + script.ext);
					})
					.catch((e) => {
						console.error(e);
						this.emitLoading(false);
					})
					.finally(() => {
						this.emitLoading(false);
					});
			}
		},
		async handleModalSubmited() {
			this.isNewExpenseModalOpen = false;
			this.$bus.$emit('filter-budget-advanced:refresh-data');
		},
		async onExpenseCarouselViewClosed() {
			this.isNewExpenseModalOpen = false;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		async handleFilterBudgetAdvancedChange(payload) {
			this.emitLoading(true);
			this.expenseItems = [];
			const result = await getProjectUserExpenseDetail(payload);

			let arr = [];
			const data = result;
			for (const item of data) {
				let exp = item.expenseItem;

				item.fullName = '';
				exp.fullName = exp.user.name + ' ' + exp.user.firstName;
				exp.statusClass = validatedColor(exp.validated);
				exp.validatedStatus = this.validatedText(exp.validated);
				arr.push(exp);
			}
			this.expenseItems = arr;
			this.emitLoading(false);
		},
		emitLoading(value) {
			this.$emit('budget-gl:loading', value);
		},
		openEditExpense(expenseItem) {
			if (expenseItem.type === 2) {
				expenseItem.originalKm = expenseItem.km;
				expenseItem.originalFromLocation = expenseItem.fromLocation;
				expenseItem.originalToLocation = expenseItem.toLocation;
			} else {
				expenseItem.originalImages = expenseItem.images;
			}
			this.newExpenseType = expenseItem.type;
			this.currentExpenseItem = expenseItem;
			const currentExpense = this.expenses.filter((expense) => +expense.id === +expenseItem.expenseId);
			this.currentExpense = currentExpense.length > 0 ? currentExpense[0] : {};
			this.isNewExpenseModalOpen = true;
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.emitLoading(false);
		},
		handleLoading(payload) {
			if (payload) {
				this.emitLoading(false);
			}
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		},
		onExpenseCarouselViewReloaded(index) {
			this.$bus.$emit('filter-budget-advanced:refresh-data');
			setTimeout(() => {
				this.isNewExpenseModalOpen = false;
			}, 500);
		},
		handleInputSelectAll(payload) {
			this.expenseItems = this.expenseItems.map(async (expenseItem) => {
				expenseItem.processed = payload;

				await changeStatusOfProcess(+expenseItem.id, 2, payload);

				return expenseItem;
			});
		}
	}
};
</script>
