var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "md",
        "header-class": "header-class-modal-doc-package",
        "header-bg-variant": "light",
        title: _vm.FormMSG(1, "Info"),
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    { staticClass: "padding-c-1" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end align-items-center w-100",
                            staticStyle: { gap: "20px" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "w-100-px d-flex justify-content-center align-items-center",
                                attrs: { size: "sm", variant: "light" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("close")
                                  },
                                },
                              },
                              [_c("span", [_vm._v("OK")])]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }