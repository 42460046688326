<style scoped>
.btn1 {
	margin-bottom: 10px;
	width: 250px;
}
</style>
<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				:class="{
					'container-layout': $screen.width >= 992,
					'container-mobile': $screen.width < 992
				}"
			>
				<b-row>
					<b-col sm="12" class="px-0">
						<h1 :class="[`${!$screen.sm && !$screen.md ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
							{{ this.FormMSG(1, 'Budget') }}
						</h1>
					</b-col>
					<!-- <pre>{{ filterBudget }}</pre>
					<pre>{{ store.getCurrentProjectConfig() }}</pre> -->
				</b-row>
				<b-row class="tab-budget">
					<b-col>
						<div style="position: relative" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
							<custom-loading-planning :loading="isLoading" :label="FormMSG(129, 'Prepare data of budgets')" />
							<b-tabs
								:value="budgetTabs"
								cards
								active-nav-item-class="font-weight-bold"
								active-tab-class="font-weight-bold"
								v-model="tabIndex"
								@activate-tab="handleActivateTab"
							>
								<!-- <b-tab v-if="canSeeDepBudget" :title="FormMSG(501, 'Department Budget')">
									<BudgetForOneDepartment />
								</b-tab> -->
								<b-tab v-if="canSeeGlobBudget" :title="FormMSG(302, 'Budget per category')" :active="activeTabPerCategory">
									<b-card-text>
										<budget-per-category
											:budget-hierarchical-data="budgetHierarchicalData"
											@budget-per-category:loading="handleBudgetPerCategory"
											@budget-per-category:change-EFC="handleBudgetPerCategoryChangeEFC"
											@budget-per-category:add-new="handleBudgetPerCategoryAddNew"
											@budget-gl:loading="handleBudgetGlLoading"
										/>
									</b-card-text>
								</b-tab>
								<b-tab v-if="canSeeGlobBudget" :title="FormMSG(400, 'Salaries')" :active="activeTabBudgetSalaries">
									<b-card-text>
										<budget-salaries
											ref="budget-salaries"
											:budget-salaries-data="budgetSalariesData"
											:init-data-for-filter-advanced="filterAdvanced"
											:total-records="totalRecordsBudgetSalariesData"
											@activate-filter-by-keyword="handleFilterSalariesByKeyword"
											@budget-salaries:change-filter="handleBudgetSalariesChangeFilter"
											@budget-salaries:loading="handleBudgetSalariesLoading"
											@budget-salaries:forward="handleBudgetSalariesForward"
											@budget-salaries:change-per-page="handleBudgetSalariesChangePerPage"
											@budget-salaries:previous="handleBudgetSalariesPrevious"
											@budget-salaries:load-more="handleBudgetSalariesLoadMore"
										/>
									</b-card-text>
								</b-tab>
								<b-tab v-if="canSeeGlobBudget" :title="FormMSG(408, 'Expense sheets')" :active="activeTabExpenseSheet">
									<b-card-text>
										<expense-sheets
											:budget-expense-sheet-data="budgetExpenseSheet"
											:total-records="totalRecordsBudgetExpenseSheetData"
											@activate-filter-by-keyword="handleFilterExpenseSheetByKeyword"
											@budget-expense-sheets:load-more="handleBudgetExpenseSheetsLoadMore"
											@budget-expense-sheets:generate-certified-start="handleBudgetExpenseSheetsGenerateCertifiedStart"
											@budget-expense-sheets:generate-certified-end="handleBudgetExpenseSheetsGenerateCertifiedEnd"
											@init-expense-sheet-data="getBudgetExpenseSheetData"
											:tabIndex="tabIndex"
											:isLoading="isLoading"
										/>
									</b-card-text>
								</b-tab>
								<b-tab v-if="canSeeGlobBudget" :title="FormMSG(401, 'Expenses')" :active="activeTabExpenseItem">
									<b-card-text>
										<budget-expenses
											ref="budget-expenses"
											:budget-expenses-data="budgetExpensesData"
											:init-data-for-filter-advanced="filterAdvanced"
											:total-records="totalRecordsBudgetExpensesData"
											@activate-filter-by-keyword="handleFilterExpenseByKeyword"
											@budget-expenses:change-filter="handleBudgetExpensesChangeFilter"
											@budget-gl:loading="handleBudgetGlLoading"
											@budget-expenses:reload="handleFilterBudgetAdvancedRefreshData"
											@budget-expenses:load-more="handleBudgetExpensesLoadMore"
										/>
									</b-card-text>
								</b-tab>
								<b-tab v-if="canSeeGlobBudget" :title="FormMSG(402, 'Purhcase orders')" :active="activeTabPO">
									<b-card-text>
										<purchase-orders-budget
											ref="budget-po"
											:budgetPOData="budgetPOData"
											:init-data-for-filter-advanced="filterAdvanced"
											:total-records="totalRecordsBudgetPOData"
											@activate-filter-by-keyword="handleFilterPoByKeyword"
											@budget-po:change-filter="handleBudgetPoChangeFilter"
											@budget-po:loading="handleBudgetPOLoading"
											@budget-po:load-more="handleBudgetPOLoadMore"
										/>
									</b-card-text>
								</b-tab>
							</b-tabs>
						</div>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag'
import { store } from '@/store';
// import { omit } from '@/shared/utils'
import languageMessages from '@/mixins/languageMessages';
// import { rendCurrency, rendKgCo2 } from '~helpers'
import {
	getBudgetHierarchicalAsJSON,
	getProjectUserSalaries,
	getProjectUserExpenseDetail,
	getProjectUserPoDetail,
	// getBudgetHierarchicalCategoryJson,
	getTotalRecordsBudgetSalaries,
	getTotalRecordsBudgetExpenses,
	getTotalRecordsBudgetPO,
	getProjectUserExpenseSheet,
	getTotalRecordsExpenseSheet
} from '@/cruds/budget.crud';
import BudgetPerCategory from '@/components/Budget/BudgetPerCategory';
import BudgetSalaries from './Salaries';
import BudgetExpenses from './Expenses';
import PurchaseOrdersBudget from './PurchaseOrders';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import TotalGeneralBudget from '@/components/Budget/TotalGeneralBudget';
import FilterBudget from '@/components/Budget/FilterBudget';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
// import { removeAttributeTree } from '@/shared/utils'
import _ from 'lodash';
// import { isNil } from '~utils'
import { mapActions, mapGetters } from 'vuex';
import BudgetMixin from '@/components/Budget/budget.mixin';
import ExpenseSheets from './ExpenseSheets';
import BudgetForOneDepartment from './BudgetForOneDepartment.vue';

// const query_compute_budget = gql`
// 	mutation {
// 		RecomputeBudget(ProjectId: null)
// 	}
// `

const queryTabs = ['activeTabPerCategory', 'activeTabBudgetSalaries', 'activeTabExpenseSheet', 'activeTabExpenseItem', 'activeTabPO'];

export default {
	name: 'BudgetGlobal',
	mixins: [languageMessages, GlobalMixin, BudgetMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ExpenseSheets,
		BudgetPerCategory,
		TotalGeneralBudget,
		FilterBudget,
		BudgetSalaries,
		BudgetExpenses,
		PurchaseOrdersBudget,
		FilterBudgetAdvanced,
		CustomLoadingPlanning,
		BudgetForOneDepartment
	},
	data() {
		return {
			hv: 'dark',
			Validated: 0,
			isOnGlobalMount: false,
			erreur: {},
			BudgetGlobal: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			tabIndex: 0,
			successModal: false,
			BudgetTotal: {
				amountTotal: 0,
				expenseTotal: 0,
				contracted: 0,
				spent: 0,
				salaries: 0,
				initial: 0,
				purchaseOrdersTotal: 0,
				amountLeft: 0,
				initialExpenses: 0,
				initialSalaries: 0,
				kgCoTwoExpenses: 0,
				kgCoTwoSalaries: 0,
				kgCoTwoTotal: 0
			},
			budgetTabs: 0,
			filter: {
				strTime: '',
				endTime: '',
				departmentId: -1
			},
			filterAdvanced: {},
			activeTabPerCategory: false,
			activeTabBudgetSalaries: false,
			activeTabPO: false,
			activeTabExpenseSheet: false,
			activeTabExpenseItem: false,
			loadingFor: {
				budgetByDepartment: false,
				budgetPerCategory: false,
				budgetExpenses: false,
				budgetSalaries: false,
				budgetPO: false
			},
			selectMode: 'single',
			selectedColor: 'primary',
			isLoading: false,
			filterActive: {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate,
				userDepartment: -1
			},
			forceCompute: true,
			offsetBudgetSalaries: 0,
			limitBudgetSalaries: 100,
			perPageBudgetSalaries: 100,
			offsetBudgetExpenses: 0,
			limitBudgetExpenses: 100,
			perPageBudgetExpenses: 100,
			offsetBudgetPO: 0,
			limitBudgetPO: 100,
			perPageBudgetPO: 100,
			offsetBudgetExpenseSheet: 0,
			limitBudgetExpenseSheet: 100,
			perPageBudgetExpenseSheet: 100,
			budgetHierarchicalData: [],
			budgetSalariesData: [],
			totalRecordsBudgetSalariesData: 0,
			budgetExpensesData: [],
			totalRecordsBudgetExpensesData: 0,
			budgetPOData: [],
			totalRecordsBudgetPOData: 0,
			budgetExpenseSheet: [],
			totalRecordsBudgetExpenseSheetData: 0,
			cookiesName: 'filter_budget_advanced',
			cookiesPaginationBudgetSalaries: 'pagination_budget_salaries',
			cookiesPaginationBudgetExpenses: 'pagination_budget_expenses',
			cookiesPaginationBudgetPO: 'pagination_budget_po',
			keywordSalariesBudget: '',
			keywordExpenseSheetBudget: '',
			keywordPOBudget: '',
			fromMount: true,
			store
		};
	},
	computed: {
		// canSeeDepBudget() {
		// 	return store.canViewDepartmentBudget();
		// },
		canSeeGlobBudget() {
			return store.canViewGlobalBudget();
		},
		...mapGetters({
			filterBudget: 'global-filter/filterBudget',
			listFilters: 'global-filter/listFilters',
			paginationFilter: 'global-filter/paginationFilter'
		}),
		filterBudgetPerCategory() {
			if (
				!_.isUndefined(this.filterBudget.forBudgetPerCategory) &&
				!_.isNil(this.filterBudget.forBudgetPerCategory) &&
				!_.isUndefined(this.filterBudget.forBudgetPerCategory.startDate) &&
				!_.isNil(this.filterBudget.forBudgetPerCategory.startDate) &&
				this.filterBudget.forBudgetPerCategory.startDate !== '' &&
				!_.isUndefined(this.filterBudget.forBudgetPerCategory.endDate) &&
				!_.isNil(this.filterBudget.forBudgetPerCategory.endDate) &&
				this.filterBudget.forBudgetPerCategory.endDate !== ''
			) {
				this.filterBudget.forBudgetPerCategory.startDate = moment(this.filterBudget.forBudgetPerCategory.startDate).format('YYYY-MM-DD') + 'T00:00:00Z';
				this.filterBudget.forBudgetPerCategory.endDate = moment(this.filterBudget.forBudgetPerCategory.endDate).format('YYYY-MM-DD') + 'T23:59:59Z';

				return {
					startDate: this.filterBudget.forBudgetPerCategory.startDate,
					endDate: this.filterBudget.forBudgetPerCategory.endDate,
					departmentId: -1,
					forceCompute: true
				};
			}

			return {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate,
				departmentId: -1,
				forceCompute: true
			};
		},
		filterBudgetSalaries() {
			if (!_.isNil(this.filterBudget.forBudgetSalaries)) {
				const filterToReturn = this.returnFilter(this.filterBudget.forBudgetSalaries);

				return filterToReturn;
			}

			return {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate
			};
		},
		paginationBudgetSalaries() {
			return this.paginationFilter.forBudgetSalaries;
		},
		filterBudgetExpenses() {
			if (!_.isNil(this.filterBudget.forBudgetExpenses)) {
				const filterToReturn = this.returnFilter(this.filterBudget.forBudgetExpenses);
				return filterToReturn;
			}

			return {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate
			};
		},
		paginationBudgetExpenses() {
			return this.paginationFilter.forBudgetExpenses;
		},
		filterBudgetPO() {
			if (!_.isNil(this.filterBudget.forBudgetPO)) {
				const filterToReturn = this.returnFilter(this.filterBudget.forBudgetPO);
				return filterToReturn;
			}

			return {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate
			};
		},
		paginationBudgetPO() {
			return this.paginationFilter.forBudgetPO;
		},
		filterBudgetExpenseSheet() {
			if (!_.isNil(this.filterBudget.forBudgetExpenseSheet)) {
				const filterToReturn = this.returnFilter(this.filterBudget.forBudgetExpenseSheet);
				return filterToReturn;
			}

			return {
				startDate: store.getCurrentProjectConfig().startDate,
				endDate: store.getCurrentProjectConfig().endDate
			};
		},
		paginationBudgetExpenseSheet() {
			return this.paginationFilter.forBudgetExpenseSheet;
		}
	},
	async mounted() {
		const query = Object.keys(this.$route.query);
		const i = query.findIndex((q) => queryTabs.includes(q));

		if (i > -1) this.checkTabOnMount();
		else this.fromMount = false;
		// HANDLE BUS EVENT
		this.$bus.$on('filter-budget-advanced:change', async (payload) => {
			this.forceCompute = true;
			await this.handleFilterBudgetAdvancedChange(payload);
		});

		this.$bus.$on('filter-budget-advanced:clear-filters', async (payload) => {
			this.forceCompute = true;
			await this.handleFilterBudgetAdvancedChange({});
		});

		this.$bus.$on('filter-budget-advanced:refresh-data', async () => {
			await this.handleFilterBudgetAdvancedRefreshData();
		});
	},
	methods: {
		...mapActions({
			getListFilters: 'global-filter/getListFilters',
			getMyFilterActive: 'global-filter/getMyFilterActive'
		}),
		handleBudgetExpenseSheetsGenerateCertifiedStart() {
			this.isLoading = true;
		},
		handleBudgetExpenseSheetsGenerateCertifiedEnd() {
			this.isLoading = false;
		},
		async handleFilterSalariesByKeyword(payload) {
			this.keywordSalariesBudget = payload;
			await this.getBudgetSalariesData(payload);
		},
		async handleFilterPoByKeyword(payload) {
			this.keywordPOBudget = payload;
			await this.getBudgetPOData(payload);
		},
		async handleFilterExpenseSheetByKeyword(payload) {
			this.keywordExpenseSheetBudget = payload;
			if (!this.isOnGlobalMount) await this.getBudgetExpenseSheetData(payload);
		},
		async handleFilterExpenseByKeyword(payload) {
			await this.getBudgetExpensesData(payload);
		},
		async handleBudgetPerCategoryAddNew() {
			await this.handleFilterBudgetAdvancedRefreshData();
		},
		async handleBudgetPerCategoryChangeEFC(payload) {
			await this.handleFilterBudgetAdvancedRefreshData();
		},
		async handleBudgetPOLoadMore({ offset, limit, perPage }) {
			this.offsetBudgetPO = offset;
			this.limitBudgetPO = limit;
			this.perPageBudgetPO = perPage;

			await this.getBudgetPOData(false);
		},
		async handleBudgetExpenseSheetsLoadMore({ offset, limit, perPage }) {
			this.offsetBudgetExpenseSheet = offset;
			this.limitBudgetExpenseSheet = limit;
			this.perPageBudgetExpenseSheet = perPage;
			await this.getBudgetExpenseSheetData();
		},
		async handleBudgetExpensesLoadMore({ offset, limit, perPage }) {
			this.offsetBudgetExpenses = offset;
			this.limitBudgetExpenses = limit;
			this.perPageBudgetExpenses = perPage;

			await this.getBudgetExpensesData();
		},
		async handleBudgetSalariesLoadMore({ offset, limit, perPage }) {
			this.offsetBudgetSalaries = offset;
			this.limitBudgetSalaries = limit;
			this.perPageBudgetSalaries = perPage;
			this.paginationBudgetSalaries.recordLimit = limit;
			this.paginationBudgetSalaries.recordOffSet = offset;

			await this.getBudgetSalariesData();
		},
		async handleBudgetSalariesForward({ offset, limit, perPage }) {
			await this.getBudgetSalariesData();
		},
		async handleBudgetSalariesChangePerPage({ offset, limit, perPage }) {
			await this.getBudgetSalariesData();
		},
		async handleBudgetSalariesPrevious({ offset, limit, perPage }) {
			await this.getBudgetSalariesData();
		},
		async handleFilterBudgetAdvancedRefreshData() {
			await this.$apollo.getClient().resetStore();
			this.forceCompute = true;
			await this.handleFilterBudgetAdvancedChange(this.filterActive);
		},
		async handleActivateTab(newTabIndex, oldIndex) {
			this.budgetTabs = newTabIndex;
			const query = {};
			if (newTabIndex === 0) {
				await this.getBudgetHierarchicalDataList();
				query['activeTabPerCategory'] = 1;
			} else if (newTabIndex === 1) {
				await this.getBudgetSalariesData();
				query['activeTabBudgetSalaries'] = 1;
			} else if (newTabIndex === 2) {
				await this.getBudgetExpenseSheetData();
				query['activeTabExpenseSheet'] = 1;
			} else if (newTabIndex === 3) {
				await this.getBudgetExpensesData();
				query['activeTabExpenseItem'] = 1;
			} else if (newTabIndex === 4) {
				await this.getBudgetPOData();
				query['activeTabPO'] = 1;
			}

			if (!this.fromMount) {
				query['searchFilter'] = this.$route.query.searchFilter;
				this.$router.push({ query });
			} else this.fromMount = false;
		},
		async getBudgetExpenseSheetData(keyword = null) {
			this.isLoading = true;

			// console.log({ filterBudgetExpenseSheet: this.filterBudgetExpenseSheet });

			let newFilterActive = {
				...this.filterBudgetExpenseSheet,
				recordOffSet: this.keywordExpenseSheetBudget ? null : this.paginationBudgetExpenseSheet.recordOffSet,
				recordLimit: this.keywordExpenseSheetBudget ? null : this.paginationBudgetExpenseSheet.recordLimit,
				keyWord: this.keywordExpenseSheetBudget
			};

			this.totalRecordsBudgetExpenseSheetData = await getTotalRecordsExpenseSheet({
				...this.filterBudgetExpenseSheet,
				keyWord: this.keywordExpenseSheetBudget
			});

			this.budgetExpenseSheet = await getProjectUserExpenseSheet(newFilterActive);
			this.isLoading = false;
			this.isOnGlobalMount = false;
		},
		async getBudgetPOData(keyword) {
			this.isLoading = true;

			let newFilterActive = {
				...this.filterBudgetPO,
				recordOffSet: this.keywordPOBudget ? null : this.paginationBudgetPO.recordOffSet,
				recordLimit: this.keywordPOBudget ? null : this.paginationBudgetPO.recordLimit,
				keyWord: this.keywordPOBudget
			};

			this.totalRecordsBudgetPOData = await getTotalRecordsBudgetPO({ ...this.filterBudgetPO, groupBySheets: true, keyWord: this.keywordPOBudget });

			this.budgetPOData = await getProjectUserPoDetail({ ...newFilterActive, groupBySheets: true });
			this.isLoading = false;
		},
		async getBudgetExpensesData(keyword) {
			this.isLoading = true;

			let newFilterActive = {
				...this.filterBudgetExpenses,
				recordOffSet: keyword ? null : this.paginationBudgetExpenses.recordOffSet,
				recordLimit: keyword ? null : this.paginationBudgetExpenses.recordLimit,
				keyWord: keyword
			};

			// console.log({ filterBudgetExpenses: this.filterBudgetExpenses });
			this.totalRecordsBudgetExpensesData = await getTotalRecordsBudgetExpenses({ ...this.filterBudgetExpenses, keyWord: keyword });

			this.budgetExpensesData = await getProjectUserExpenseDetail({ ...newFilterActive });
			// console.log("budget expense data", this.budgetExpensesData);
			this.isLoading = false;
		},
		async getBudgetSalariesData(keyword) {
			this.isLoading = true;

			let newFilterActive = {
				...this.filterBudgetSalaries,
				recordOffSet: this.keywordSalariesBudget ? null : this.paginationBudgetSalaries.recordOffSet,
				recordLimit: this.keywordSalariesBudget ? null : this.paginationBudgetSalaries.recordLimit,
				keyWord: this.keywordSalariesBudget
			};

			this.totalRecordsBudgetSalariesData = await getTotalRecordsBudgetSalaries({ ...this.filterBudgetSalaries, keyWord: this.keywordSalariesBudget });

			this.budgetSalariesData = await getProjectUserSalaries({ ...newFilterActive });
			this.isLoading = false;
		},
		async getBudgetHierarchicalDataList() {
			this.isLoading = true;
			// console.log({ filterBudgetPerCategory: this.filterBudgetPerCategory });
			const result = await getBudgetHierarchicalAsJSON(
				this.filterBudgetPerCategory.startDate,
				this.filterBudgetPerCategory.endDate,
				this.filterBudgetPerCategory.departmentId,
				this.filterBudgetPerCategory.forceCompute
			);
			// console.log({ result, filterBudgetPerCategory: this.filterBudgetPerCategory });
			if (result === 'null') {
				this.budgetHierarchicalData = [];
			} else {
				this.budgetHierarchicalData = JSON.parse(result);
			}

			this.isLoading = false;
		},
		handleBudgetPerCategory(payload) {
			this.loadingFor.budgetPerCategory = payload;
		},
		handleBudgetPOLoading(payload) {
			this.isLoading = payload;
		},
		handleBudgetSalariesLoading(payload) {
			this.isLoading = payload;
		},
		handleBudgetGlLoading(payload) {
			this.isLoading = payload;
		},
		async handleBudgetSalariesChangeFilter(payload) {
			this.filterAdvanced = payload;
		},
		async handleBudgetExpensesChangeFilter(payload) {
			this.filterAdvanced = payload;
		},
		async handleBudgetPoChangeFilter(payload) {
			this.filterAdvanced = payload;
		},
		initFilterTime() {
			this.filter.strTime = store.getCurrentProjectConfig().startDate;
			this.filter.endTime = store.getCurrentProjectConfig().endDate;
		},
		async handleFilterBudgetAdvancedChange(payload) {
			this.filterActive = payload;
			if (!payload.startDate && !payload.endDate) {
				this.filterActive.startDate = store.getCurrentProjectConfig().startDate;
				this.filterActive.endDate = store.getCurrentProjectConfig().endDate;
			}

			if (payload.startDate && !payload.endDate) {
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = store.getCurrentProjectConfig().endDate;
			}
			if (!payload.startDate && payload.endDate) {
				this.filterActive.startDate = store.getCurrentProjectConfig().startDate;
				this.filterActive.endDate = payload.endDate;
			}
			if (payload.startDate && payload.endDate) {
				this.filterActive.startDate = payload.startDate;
				this.filterActive.endDate = payload.endDate;
			}
			if (!payload.userDepartment) {
				this.filterActive.userDepartment = -1;
			}

			await this.handleActivateTab(this.budgetTabs);
			//this.filter.forceCompute = true
		},
		checkTabOnMount() {
			if (this.$route.query.activeTabPerCategory) {
				this.activeTabPerCategory = true;
				this.fromMount = false;
			} else {
				this.activeTabPerCategory = false;
			}

			if (this.$route.query.activeTabBudgetSalaries) {
				this.activeTabBudgetSalaries = true;
				this.keywordSalariesBudget = this.$route.query.filterSearch;
			} else {
				this.activeTabBudgetSalaries = false;
			}

			if (this.$route.query.activeTabPO) {
				this.activeTabPO = true;
				this.keywordPOBudget = this.$route.query.filterSearch;
			} else {
				this.activeTabPO = false;
			}

			if (this.$route.query.activeTabExpenseSheet) {
				this.activeTabExpenseSheet = true;
				this.keywordExpenseSheetBudget = this.$route.query.filterSearch;
			} else {
				this.activeTabExpenseSheet = false;
			}

			this.activeTabExpenseItem = this.$route.query.activeTabExpenseItem ? true : false;
		}
	},
	async created() {
		this.isOnGlobalMount = true;
		// this.tabIndex = this.$route.query.tab || 0;
		moment.locale(this.lang);
		this.initFilterTime();

		await this.getMyFilterActive(0);
		await this.getMyFilterActive(1);
		await this.getMyFilterActive(2);
		await this.getMyFilterActive(3);

		await this.getListFilters(0);
		await this.getListFilters(1);
		await this.getListFilters(2);
		await this.getListFilters(3);

		if (Object.keys(this.$route.query).length <= 1) await this.getBudgetHierarchicalDataList();

		this.$bus.$on('specifics-po-delete-action', async () => {
			await this.getBudgetPOData();
			await this.handleFilterBudgetAdvancedRefreshData();
		});
	},
	beforeDestroy() {
		// Don't forget to remove the event listener to avoid memory leaks
		this.$bus.$off('filter-budget-advanced:change');
		this.$bus.$off('filter-budget-advanced:clear-filters');
		this.$bus.$off('specifics-po-delete-action');
		this.$bus.$off('filter-budget-advanced:refresh-data');
	}
};
</script>

<style lang="scss" scoped>
.tab-budget {
	background-color: #ffffff;
	border-radius: 2px;
}
</style>
