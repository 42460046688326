<template>
	<div
		id="context-menu"
		class="d-flex align-items-center justify-content-center flex-column position-absolute custom-context-menu"
		:style="{ left: `${menuX}px`, top: `${menuY}px` }"
	>
		<section class="section-item">
			<div v-if="isMarked" class="d-flex flex-row align-items-center gap-4 p-2 item" @click="onMarkToProcessed">
				<div class="w-15">
					<component :is="getLucideIcon('Check')" color="#00A09C" :size="18" :stroke-width="3" />
				</div>
				<div class="w-85">
					<span>{{ FormMSG(1, 'Mark as processed') }}</span>
				</div>
			</div>
			<div v-if="isUnmarked" class="d-flex flex-row align-items-center gap-4 p-2 item" @click="onUnMarkToProcessed">
				<div class="w-15">
					<component :is="getLucideIcon('X')" color="#225CBD" :size="18" :stroke-width="3" />
				</div>
				<div class="w-85">
					<span>{{ FormMSG(2, 'Unmark as processed') }}</span>
				</div>
			</div>
			<div class="w-100">
				<button @click="onClose">{{ FormMSG(3, 'Close') }}</button>
			</div>
		</section>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
export default {
	name: 'BudgetContextMenu',
	mixins: [languageMessages, globalMixin],
	props: {
		menuX: {
			type: Number,
			required: false
		},
		menuY: {
			type: Number,
			required: false
		},
		isUnmarked: {
			type: Boolean,
			required: false,
			default: false
		},
		isMarked: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({}),
	methods: {
		onClose() {
			this.$emit('onClose');
		},
		onMarkToProcessed() {
			this.$emit('on-mark-to-proccessed');
		},
		onUnMarkToProcessed() {
			this.$emit('on-mark-to-un-proccessed');
		}
	}
};
</script>
<style lang="scss">
.custom-context-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 4px;
	background-color: #ffff;
	border: 1px solid black;
	padding: 6px;
	width: 250px;
	.section-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 4px;
		.w-15 {
			width: 15%;
		}
		.w-85 {
			width: 85%;
		}
	}
	.item {
		width: 240px;
		cursor: pointer;
		border-bottom: 1px solid #e2e6eb;
		&:hover {
			border-radius: 4px;
			background-color: hsl(206, 96%, 90%);
		}
	}
	button {
		width: 240px;
		border: none;
		font-weight: 600;
		&:hover {
			background-color: #bec1c1;
		}
	}
	.gap-4 {
		gap: 4px;
	}
}
</style>
