<template>
	<div class="container-layout form px-0">
		<filter-budget-advanced
			hide-supplier-section
			:show-type-selector="false"
			:label-expense-details="FormMSG(148, 'Salary details')"
			hide-expense-details-type
			hide-co-two-detail
			use-department-for-salary
			:hide-process-filter="false"
			:filter-type="FILTER_TYPE.BUDGET_SALARIES"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
		/>
		<div class="d-flex row align-items-center mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch
							id="salaire-id"
							:init-value="initValueFilter"
							@filtered-users="activeFilter"
							@handle-init-filter="onHandleInitFilter"
						/>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="FILTER_TYPE.BUDGET_SALARIES"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</div>
		<div class="row mt-3 px-3">
			<CardListBuilder custom-class="hide-on-desktop" v-if="!$screen.md" :items="salaries" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="data">
					<div @click="goToDetail(data.item)" role="button"><eye :size="16" /> {{ FormMSG(1001, 'See details') }}</div>
				</template>
			</CardListBuilder>
			<b-table
				v-else
				responsive="sm"
				:filter="filter"
				:items="salaries"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				selectable
				:selectedVariant="selectedColor"
				:select-mode="selectMode"
				:empty-text="FormMSG(285, 'No budget salaries found')"
			>
				<!-- <template #head(processed)="">
					<div class="text-center w-100 p-1" style="margin-left: 3px" id="processed-all">
						<b-form-checkbox :disabled="!canMarkEntryAsProcessed" v-model="selectAll" size="lg" @change="handleInputSelectAll">
							<div class="mt-1" style="color: #fff">{{ FormMSG(561, 'Processed') }}</div>
						</b-form-checkbox>
					</div>
				</template> -->
				<template #cell(processed)="{ item, index }">
					<div>
						<b-form-checkbox
							v-model="item.timesheet.processed"
							:disabled="!canMarkEntryAsProcessed"
							size="lg"
							:value="true"
							:unchecked-value="false"
							@change="handleChangeProcess($event, item, index)"
						/>
					</div>
				</template>
				<template #cell(categorySelect)="{}">
					<div>
						<treeselect
							:options="allCategories"
							:multiple="false"
							:clearable="false"
							disable-branch-nodes
							:default-expand-level="1"
							@input="handleInputCategorySelect"
						>
							<div slot="option-label" slot-scope="{ node }">
								<div class="treeselect-label" :title="node.label">
									{{ node.label }}
								</div>
							</div>
						</treeselect>
					</div>
				</template>
				<template #cell(validatedStatus)="data">
					<div class="wrap-status">
						<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
							{{ data.item.validatedStatus }}
						</div>
					</div>
				</template>
				<template #cell(detail)="data">
					<div @click="goToDetail(data.item)" role="button">
						<eye :size="16" />
					</div>
				</template>
				<template #cell(salary)="data">
					<div class="d-flex flex-row align-items-center justify-content-end w-100">
						<salary-log :item="data.item.timesheet" />
						<div style="margin-top: 2px">{{ rendCurrency(data.item.salary) }}</div>
					</div>
				</template>
			</b-table>
		</div>
		<script-export-modal
			v-model="isScriptTimeSheetReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="ts"
			@script-export-modal:loading="handleTimesheetLoading"
			@script-export-modal:closed="timeSheetRerpotOnClosed"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { Eye, Search, X, AlertTriangle } from 'lucide-vue';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
// import { getProjectUserSalaries, getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud'
import { store } from '@/store';
import Treeselect from '@riophae/vue-treeselect';
import CustomPagination from '@/components/Budget/CustomPagination';
import _ from 'lodash';
import filterInputSearch from '../users/filterInput.vue';
import { getScripts } from '@/cruds/script.crud';
import { timeSheetReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, FILTER_TYPE } from '@/shared/constants';
// import { formatFileName } from '~utils';
import { getFileExtension } from '@/shared/helpers';
import { changeStatusOfProcess } from '@/cruds/budget.crud';
import SalaryLog from '@/components/Timesheet/SalaryLog';

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'BudgetSalaries',
	components: {
		Eye,
		FilterBudgetAdvanced,
		Search,
		X,
		Treeselect,
		filterInputSearch,
		AlertTriangle,
		CustomPagination,
		ScriptExportModal,
		SalaryLog
	},
	mixins: [languageMessages, GlobalMixin],
	props: {
		initDataForFilterAdvanced: { type: Object, required: true },
		budgetSalariesData: { type: Array, required: true },
		totalRecords: { type: Number, required: true }
	},
	data() {
		return {
			visible: false,
			keyword: null,
			caption1: '',
			hv: 'dark',
			haveActiveFilter: false,
			salaries: [],
			filter: '',
			filterForDownload: {
				startDate: null,
				endDate: null
			},
			filtersSelected: {},
			firstLoad: true,
			selectMode: 'single',
			selectedColor: 'primary',
			perPage: 100,
			offset: 0,
			limit: 100,
			cookiesPaginationBudgetSalaries: 'pagination_budget_salaries',
			isScriptTimeSheetReportOpen: false,
			scripts: [],
			customReport: null,
			scriptType: 11,
			removeFromReportKeys: [],
			initValueFilter: '',
			rendCurrency,
			FILTER_TYPE,
			selectAll: false,
			processedIds: []
		};
	},
	watch: {
		budgetSalariesData: {
			handler(val) {
				let arr = [];
				const data = val;
				let tmpSelectProcessedAll = false;
				this.selectAll = false;
				this.processedIds = [];

				for (const item of data) {
					item.statusClass = validatedColor(item.status);
					item.validatedStatus = this.validatedText(item.status);

					if (item.timesheet.processed === true) {
						this.processedIds.push(item.timesheet.id.toString());
					}

					if (tmpSelectProcessedAll === false) {
						if (item.timesheet.processed === true) {
							this.selectAll = true;
							tmpSelectProcessedAll = true;
						}
					}

					arr.push(item);
				}
				this.salaries = arr;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		canMarkEntryAsProcessed() {
			return store.canMarkEntryAsProcessed();
		},
		bgFields() {
			return [
				{
					key: 'processed',
					sortable: false,
					class: 'text-center w-90-px'
				},
				{
					key: 'startDate',
					label: this.FormMSG(16, 'Stard Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'endDate',
					label: this.FormMSG(20, 'End Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: this.FormMSG(21, 'Full Name'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'departmentAndFunction',
					label: this.FormMSG(122, 'Department & Function'),
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					},
					sortable: true,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'costCenterAndcategoryName',
					label: this.FormMSG(123, 'Category'),
					formatter: (valu, key, item) => {
						return `${item.categoryCostCenter} - ${item.categoryName}`;
					},
					sortable: true,
					sortByFormatted: true,
					class: 'text-left w-250-px'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'hoursStr',
					label: this.FormMSG(127, 'Hours'),
					formatter: (value) => value,
					class: 'text-center',
					sortable: true
				},
				{
					key: 'salary',
					label: this.FormMSG(25, 'Salary'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'co2',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'detail',
					label: this.FormMSG(128, 'Details'),
					class: 'text-center'
				}
			];
		},
		bgFieldsMobile() {
			return [
				{
					key: 'startDate',
					label: this.FormMSG(16, 'Stard Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'endDate',
					label: this.FormMSG(20, 'End Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: this.FormMSG(21, 'Full Name'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(122, 'Department & Function'),
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					},
					sortable: true,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'categorySelect',
					label: this.FormMSG(123, 'Category'),
					sortable: false,
					class: 'text-left'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'hoursStr',
					label: this.FormMSG(127, 'Hours'),
					formatter: (value) => value,
					class: 'text-center',
					sortable: true
				},
				{
					key: 'salary',
					label: this.FormMSG(25, 'Salary'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'co2',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				}
			];
		}
	},
	created() {
		this.caption1 = this.FormMSG(1, 'Show filters');
	},
	async mounted() {
		if (this.$route.query.activeTabBudgetSalaries) {
			this.initValueFilter = this.$route.query.filterSearch;
			this.filter = this.$route.query.filterSearch;
			this.keyword = this.$route.query.filterSearch;
		}
		this.$bus.$on('filter-budget-advanced:clear-filters', () => {
			this.offset = 0;
			this.limit = this.perPage;
		});
	},
	methods: {
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		activeFilter(keyword) {
			this.haveActiveFilter = true;
			this.filter = keyword;
			this.keyword = keyword;
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.keyword = null;
			this.haveActiveFilter = false;
			this.$emit('activate-filter-by-keyword', this.keyword);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.timesheet.id, 0, payload);

			if (payload === false) {
				const idx = this.processedIds.findIndex((id) => id === item.timesheet.id.toString());
				if (idx !== -1) {
					this.processedIds.splice(idx, 1);
				}
			}

			this.selectAll = this.processedIds.length > 0;
		},
		handleCustomPaginationPrevious({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;
			this.$emit('budget-salaries:previous', {
				offset,
				limit,
				perPage
			});
		},
		handleCustomPaginationForward({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-salaries:forward', {
				offset,
				limit,
				perPage
			});
		},
		handleCustomPaginationChangePerPage({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;
			this.$emit('budget-salaries:change-per-page', {
				offset,
				limit,
				perPage
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;
			this.$emit('budget-salaries:load-more', {
				offset: this.offset,
				limit: this.limit,
				perPage: this.perPage
			});
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptTimeSheetReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};
				}

				let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_TS, script.id, script.fileName);

				await timeSheetReport(payload, script.id)
					.then((result) => {
						this.forceDownload(result, fileName + '.' + script.ext);
					})
					.catch((e) => {
						console.error(e);
						this.emitLoading(false);
					})
					.finally(() => {
						this.emitLoading(false);
					});
			}
		},
		goToDetail(item) {
			this.$router.push({ path: `/mytimesheets/${item.id.toString()}?budgetSalaries=1&filterSearch=${this.filter}` });
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		},
		emitLoading(value) {
			this.$emit('budget-salaries:loading', value);
		},
		timeSheetRerpotOnClosed() {
			this.isScriptTimeSheetReportOpen = false;
			this.emitLoading(false);
		},
		handleTimesheetLoading(payload) {
			if (payload) {
				this.emitLoading(false);
			}
		},
		handleInputSelectAll(payload) {
			this.salaries = this.salaries.map(async (salary) => {
				salary.timesheet.processed = payload;

				await changeStatusOfProcess(+salary.timesheet.id, 0, payload);

				return salary;
			});

			if (payload === false) {
				this.processedIds = [];
			}
		}
	}
};
</script>
