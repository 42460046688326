<template>
	<!-- message dialog -->
	<b-modal
		v-model="showModal"
		size="md"
		header-class="header-class-modal-doc-package"
		header-bg-variant="light"
		:title="FormMSG(1, 'Info')"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<slot></slot>
		<template #modal-footer>
			<div class="w-100">
				<b-row class="padding-c-1">
					<b-col cols="12">
						<div class="d-flex flex-row justify-content-end align-items-center w-100" style="gap: 20px">
							<b-button size="sm" variant="light" class="w-100-px d-flex justify-content-center align-items-center" @click="$emit('close')">
								<span>OK</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</template>
	</b-modal>
	<!-- message dialog -->
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'InfoMessageModal',
	props: {
		showModal: { type: Boolean, required: true }
	},
	mixins: [languageMessages, GlobalMixin],

	data() {
		return {};
	}
};
</script>
