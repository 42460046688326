var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class: {
              "container-layout": _vm.$screen.width >= 992,
              "container-mobile": _vm.$screen.width < 992,
            },
          },
          [
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "px-0", attrs: { sm: "12" } }, [
                  _c(
                    "h1",
                    {
                      class: [
                        `${
                          !_vm.$screen.sm && !_vm.$screen.md
                            ? "main-page-title"
                            : ""
                        }`,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(this.FormMSG(1, "Budget")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "tab-budget" },
              [
                _c("b-col", [
                  _c(
                    "div",
                    {
                      class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c("custom-loading-planning", {
                        attrs: {
                          loading: _vm.isLoading,
                          label: _vm.FormMSG(129, "Prepare data of budgets"),
                        },
                      }),
                      _c(
                        "b-tabs",
                        {
                          attrs: {
                            value: _vm.budgetTabs,
                            cards: "",
                            "active-nav-item-class": "font-weight-bold",
                            "active-tab-class": "font-weight-bold",
                          },
                          on: { "activate-tab": _vm.handleActivateTab },
                          model: {
                            value: _vm.tabIndex,
                            callback: function ($$v) {
                              _vm.tabIndex = $$v
                            },
                            expression: "tabIndex",
                          },
                        },
                        [
                          _vm.canSeeGlobBudget
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(
                                      302,
                                      "Budget per category"
                                    ),
                                    active: _vm.activeTabPerCategory,
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("budget-per-category", {
                                        attrs: {
                                          "budget-hierarchical-data":
                                            _vm.budgetHierarchicalData,
                                        },
                                        on: {
                                          "budget-per-category:loading":
                                            _vm.handleBudgetPerCategory,
                                          "budget-per-category:change-EFC":
                                            _vm.handleBudgetPerCategoryChangeEFC,
                                          "budget-per-category:add-new":
                                            _vm.handleBudgetPerCategoryAddNew,
                                          "budget-gl:loading":
                                            _vm.handleBudgetGlLoading,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canSeeGlobBudget
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(400, "Salaries"),
                                    active: _vm.activeTabBudgetSalaries,
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("budget-salaries", {
                                        ref: "budget-salaries",
                                        attrs: {
                                          "budget-salaries-data":
                                            _vm.budgetSalariesData,
                                          "init-data-for-filter-advanced":
                                            _vm.filterAdvanced,
                                          "total-records":
                                            _vm.totalRecordsBudgetSalariesData,
                                        },
                                        on: {
                                          "activate-filter-by-keyword":
                                            _vm.handleFilterSalariesByKeyword,
                                          "budget-salaries:change-filter":
                                            _vm.handleBudgetSalariesChangeFilter,
                                          "budget-salaries:loading":
                                            _vm.handleBudgetSalariesLoading,
                                          "budget-salaries:forward":
                                            _vm.handleBudgetSalariesForward,
                                          "budget-salaries:change-per-page":
                                            _vm.handleBudgetSalariesChangePerPage,
                                          "budget-salaries:previous":
                                            _vm.handleBudgetSalariesPrevious,
                                          "budget-salaries:load-more":
                                            _vm.handleBudgetSalariesLoadMore,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canSeeGlobBudget
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(408, "Expense sheets"),
                                    active: _vm.activeTabExpenseSheet,
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("expense-sheets", {
                                        attrs: {
                                          "budget-expense-sheet-data":
                                            _vm.budgetExpenseSheet,
                                          "total-records":
                                            _vm.totalRecordsBudgetExpenseSheetData,
                                          tabIndex: _vm.tabIndex,
                                          isLoading: _vm.isLoading,
                                        },
                                        on: {
                                          "activate-filter-by-keyword":
                                            _vm.handleFilterExpenseSheetByKeyword,
                                          "budget-expense-sheets:load-more":
                                            _vm.handleBudgetExpenseSheetsLoadMore,
                                          "budget-expense-sheets:generate-certified-start":
                                            _vm.handleBudgetExpenseSheetsGenerateCertifiedStart,
                                          "budget-expense-sheets:generate-certified-end":
                                            _vm.handleBudgetExpenseSheetsGenerateCertifiedEnd,
                                          "init-expense-sheet-data":
                                            _vm.getBudgetExpenseSheetData,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canSeeGlobBudget
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(401, "Expenses"),
                                    active: _vm.activeTabExpenseItem,
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("budget-expenses", {
                                        ref: "budget-expenses",
                                        attrs: {
                                          "budget-expenses-data":
                                            _vm.budgetExpensesData,
                                          "init-data-for-filter-advanced":
                                            _vm.filterAdvanced,
                                          "total-records":
                                            _vm.totalRecordsBudgetExpensesData,
                                        },
                                        on: {
                                          "activate-filter-by-keyword":
                                            _vm.handleFilterExpenseByKeyword,
                                          "budget-expenses:change-filter":
                                            _vm.handleBudgetExpensesChangeFilter,
                                          "budget-gl:loading":
                                            _vm.handleBudgetGlLoading,
                                          "budget-expenses:reload":
                                            _vm.handleFilterBudgetAdvancedRefreshData,
                                          "budget-expenses:load-more":
                                            _vm.handleBudgetExpensesLoadMore,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canSeeGlobBudget
                            ? _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(402, "Purhcase orders"),
                                    active: _vm.activeTabPO,
                                  },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("purchase-orders-budget", {
                                        ref: "budget-po",
                                        attrs: {
                                          budgetPOData: _vm.budgetPOData,
                                          "init-data-for-filter-advanced":
                                            _vm.filterAdvanced,
                                          "total-records":
                                            _vm.totalRecordsBudgetPOData,
                                        },
                                        on: {
                                          "activate-filter-by-keyword":
                                            _vm.handleFilterPoByKeyword,
                                          "budget-po:change-filter":
                                            _vm.handleBudgetPoChangeFilter,
                                          "budget-po:loading":
                                            _vm.handleBudgetPOLoading,
                                          "budget-po:load-more":
                                            _vm.handleBudgetPOLoadMore,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }