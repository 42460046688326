<template>
	<div v-click-outside="setShowFilter" class="container-filter-budget form">
		<b-button :variant="!haveFilter ? 'outline-primary' : 'warning'" @click="handleClickAdvancedFilter">
			<i class="fa fa-filter" aria-hidden="true" />
			{{ FormMSG(45, 'Advanced filter') }}
		</b-button>
		<div v-show="showFilter" class="filter-budget-content w-450">
			<b-card :title="FormMSG(50, 'Actif filters')" class="mb-0">
				<b-card-text>
					<b-row v-if="showDateFilter">
						<b-col cols="5">
							<b-form-group :label="FormMSG(309, 'Start date')" label-size="sm">
								<b-datepicker
									:value="dataToSend.startDate"
									:locale="lang"
									:max="getMaxStartDate"
									:placeholder="FormMSG(306, 'Choose date')"
									size="sm"
									:date-format-options="dateOptions"
									@input="handleInputStartDate"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="5">
							<b-form-group :label="FormMSG(308, 'End date')" size="sm">
								<b-datepicker
									:value="dataToSend.endDate"
									:locale="lang"
									:min="getMinEndDate"
									:placeholder="FormMSG(306, 'Choose date')"
									size="sm"
									:date-format-options="dateOptions"
									@input="handleInputEndDate"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="2">
							<b-button variant="danger" size="sm" class="mt-4" @click="handleDeleteDateFilter">
								<x :size="16" />
							</b-button>
						</b-col>
					</b-row>
					<b-row v-if="showCoTwoCategory">
						<b-col cols="10">
							<b-form-group :label="FormMSG(215, 'CO2 Category')">
								<b-form-select v-model="selectedCoTwoCategory" :options="co2CategoriesMenu" size="sm" @change="handleChangeCoTwoCategory" />
							</b-form-group>
						</b-col>
						<b-col cols="2">
							<b-button variant="danger" size="sm" class="mt-4">
								<x :size="16" />
							</b-button>
						</b-col>
					</b-row>
					<b-row v-show="showDepartmentFilter">
						<b-col cols="10">
							<b-form-group :label="FormMSG(203, 'Department')" label-for="new-expense_ticket-department">
								<b-form-select
									id="new-expense_ticket-department"
									v-model="selectedDepartment"
									:options="mapDepartments"
									size="sm"
									@change="handleChangeDepartment"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="2">
							<b-button variant="danger" size="sm" class="mt-4" @click="hendleDeleteDepartmentFilter">
								<x :size="16" />
							</b-button>
						</b-col>
					</b-row>
					<b-row v-if="showSelectFilter">
						<b-col cols="10">
							<b-form-group label="Filter" label-size="sm" horizontal label-cols="3">
								<b-form-select v-model="filterSelected" size="sm" @change="handleChangeSelectFilter">
									<b-form-select-option :value="null">
										{{ FormMSG(46, 'Select filter') }}
									</b-form-select-option>
									<b-form-select-option :value="'date'" :disabled="showDateFilter">
										{{ FormMSG(47, 'By start date and end date') }}
									</b-form-select-option>
									<b-form-select-option :value="'coTwoCategory'" :disabled="showCoTwoCategory">
										{{ FormMSG(163, 'By CO2 Category') }}
									</b-form-select-option>
									<b-form-select-option v-if="!hideDepartmentFilter" :value="'department'" :disabled="showDepartmentFilter">
										{{ FormMSG(48, 'By department') }}
									</b-form-select-option>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col cols="2">
							<b-button variant="danger" size="sm" type="button" @click="showSelectFilter = false">
								<x :size="16" />
							</b-button>
						</b-col>
					</b-row>
					<button v-show="showAddFilter" class="btn btn-link text-decoration-none" @click="showSelectFilter = true">
						<plus :size="16" /> {{ FormMSG(49, 'Add a filter') }}
					</button>
				</b-card-text>
			</b-card>
		</div>
	</div>
</template>

<script>
import { Plus, X } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
	name: 'FilterBudget',

	mixins: [languageMessages, GlobalMixin],

	props: {
		hideDepartmentFilter: { type: Boolean, default: false },
		cookiesName: { type: String, required: true }
	},

	components: {
		Plus,
		X
	},

	data() {
		return {
			showFilter: false,
			filterSelected: null,
			selectedDepartment: null,
			selectedCoTwoCategory: 0,
			showSelectFilter: true,
			showDateFilter: false,
			showDepartmentFilter: false,
			showCoTwoCategory: false,
			dataToSend: {
				startDate: '2019-01-01',
				endDate: new Date().toISOString().split('T')[0]
			}
		};
	},

	computed: {
		co2CategoriesMenu() {
			let menues = this.FormMenu(1334);
			return menues;
		},
		...mapGetters({
			allDepartements: 'expenses/departmentsForSalary'
		}),

		showAddFilter() {
			if (this.showDateFilter && this.showDepartmentFilter) {
				return false;
			}

			return !this.showSelectFilter;
		},

		mapDepartments() {
			const departments = this.allDepartements.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			// return departments;
			var txt = this.FormMSG(205, 'Choose a department');
			return [{ value: null, text: txt }, ...departments];
			// return [{ value: null, text: "Select a department" }, ...departments];
		},
		dateOptions() {
			return {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			};
		},
		getMaxStartDate() {
			const endDate = new Date(moment(this.dataToSend.endDate)).toISOString();
			return endDate.split('T')[0];
		},
		getMinEndDate() {
			const startDate = new Date(moment(this.dataToSend.startDate).add(2, 'days')).toISOString();
			return startDate.split('T')[0];
		},
		haveFilter() {
			return this.showDateFilter || this.showDepartmentFilter || this.showCoTwoCategory;
		}
	},

	async mounted() {
		await this.$store.dispatch('expenses/getDepartmentsForSalary');
		this.getCookies();
	},

	created() {
		this.initFilterTime();
	},

	methods: {
		getCookies() {
			if (this.$cookies.isKey(this.cookiesName)) {
				const ckFilter = this.$cookies.get(this.cookiesName);
				this.filterSelected = ckFilter.filterSelected;
				this.selectedDepartment = ckFilter.selectedDepartment;
				this.selectedCoTwoCategory = ckFilter.selectedCoTwoCategory;
				this.showSelectFilter = ckFilter.showSelectFilter;
				this.showDateFilter = ckFilter.showDateFilter;
				this.showDepartmentFilter = ckFilter.showDepartmentFilter;
				this.showCoTwoCategory = ckFilter.showCoTwoCategory;
				this.dataToSend = ckFilter.dataToSend;
			} else {
				this.setCookies();
			}
		},
		setCookies() {
			this.$cookies.set(
				this.cookiesName,
				{
					showFilter: this.showFilter,
					filterSelected: this.filterSelected,
					selectedDepartment: this.selectedDepartment,
					selectedCoTwoCategory: this.selectedCoTwoCategory,
					showSelectFilter: this.showSelectFilter,
					showDateFilter: this.showDateFilter,
					showDepartmentFilter: this.showDepartmentFilter,
					showCoTwoCategory: this.showCoTwoCategory,
					dataToSend: this.dataToSend
				},
				'7d'
			);
		},
		initFilterTime() {
			this.dataToSend.startDate = new Date(moment().startOf('months').add(1, 'days')).toISOString();
			this.dataToSend.startDate = this.dataToSend.startDate.split('T')[0];
			this.dataToSend.endDate = new Date().toISOString();
			this.dataToSend.endDate = this.dataToSend.endDate.split('T')[0];
		},
		handleDeleteDateFilter() {
			this.initFilterTime();
			this.showDateFilter = false;
			this.emitEvent();
		},
		hendleDeleteDepartmentFilter() {
			this.dataToSend.departmentId = null;
			this.showDepartmentFilter = false;
			this.emitEvent();
		},
		handleClickAddFilter() {
			this.emitEvent();
		},
		handleClickAdvancedFilter() {
			this.showFilter = true;
		},
		handleInputStartDate(payload) {
			const startDate = new Date(payload).getTime();
			const endDate = new Date(this.dataToSend.endDate).getTime();
			this.dataToSend.startDate = payload;

			if (startDate < endDate) {
				this.emitEvent();
			}
		},
		handleInputEndDate(payload) {
			const endDate = new Date(payload).getTime();
			const startDate = new Date(this.dataToSend.startDate).getTime();
			this.dataToSend.endDate = payload;

			if (endDate > startDate) {
				this.emitEvent();
			}
		},
		handleChangeCoTwoCategory(payload) {
			this.selectedCoTwoCategory = payload;
			this.emitEvent();
		},
		handleChangeDepartment(payload) {
			this.selectedDepartment = payload;
			this.emitEvent();
		},
		emitEvent() {
			this.setCookies();
			this.$emit('filter-budget:change', {
				co2Category: this.selectedCoTwoCategory,
				departmentId: this.selectedDepartment,
				startDate: this.dataToSend.startDate + 'T00:00:00Z',
				endDate: this.dataToSend.endDate + 'T23:59:00Z'
			});
		},
		handleChangeSelectFilter(payload) {
			if (payload === 'date') {
				this.showDateFilter = true;
			} else if (payload === 'department') {
				this.showDepartmentFilter = true;
			} else if (payload === 'coTwoCategory') {
				this.showCoTwoCategory = true;
			}

			this.showSelectFilter = false;
			this.filterSelected = null;
			this.emitEvent();
		},
		setShowFilter() {
			this.showFilter = false;
		}
	}
};
</script>
