<template>
	<div class="container-layout form px-0">
		<!-- <b-row v-if="stateError.show">
			<b-col>
				<b-alert show dismissible @dismissed="handleDismissedError">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row> -->
		<filter-budget-advanced
			:show-user-selector="false"
			:label-expense-details="FormMSG(169, 'Expense details')"
			use-department-for-expense
			hide-supplier-section
			hide-expense-details-department
			hide-expense-details-category
			hide-co-two-detail
			:hide-process-filter="false"
			:filter-type="FILTER_TYPE.BUDGET_EXPENSE_SHEETS"
			@filter-budget-advanced:click-selection="handleFilterBudgetAdvancedClickSelection"
			:hide-flag-filter="false"
		/>
		<b-row class="mt-3 hide-on-tablet">
			<b-col md="5">
				<b-form-group class="mb-0">
					<b-input-group v-if="$screen.width >= 992">
						<filterInputSearch
							id="expense-sheet"
							:init-value="initValueFilter"
							@filtered-users="activeFilter"
							@handle-init-filter="onHandleInitFilter"
						/>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="7">
				<custom-pagination
					v-if="!haveActiveFilter"
					:total-records="totalRecords"
					:initial-perpage="perPage"
					:initial-offset="offset"
					:initial-limit="limit"
					:filter-type="FILTER_TYPE.BUDGET_EXPENSE_SHEETS"
					@custom-pagination:previous="handleClickLoadMore"
					@custom-pagination:forward="handleClickLoadMore"
					@custom-pagination:change-per-page="handleClickLoadMore"
				/>
			</b-col>
		</b-row>
		<b-row class="mt-3 ml-1">
			<!-- <pre>{{ expenseSheets }}</pre> -->
			<CardListBuilder class="hide-on-desktop" v-if="!$screen.md" :items="expenses" :filter="filter" style="width: 100%" :fields="bgFieldsMobile">
				<template slot="actions" slot-scope="">
					<div class="mt-2"></div>
				</template>
			</CardListBuilder>
			<b-table
				v-else
				responsive="sm"
				:filter="filter"
				:items="mapExpenseSheets"
				:fields="bgFields"
				style="width: 100%; font-weight: 500"
				sticky-header="700px"
				id="table-expense-sheets"
				:head-variant="hv"
				:hover="true"
				bordered
				small
				show-empty
				:selectable="false"
				tbody-tr-class="timeline-cursor"
				:empty-text="FormMSG(265, 'No expense sheets found')"
			>
				<template #head(date)="{ field }">
					<div id="date" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(date)="{ item }">
					<div class="d-flex align-items-center justify-content-center w-100">
						<div>{{ dateFormat(item.expense.date) }}</div>
					</div>
				</template>

				<template #head(id)="{ field }">
					<div id="id" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(id)="{ item }">
					<div class="d-flex align-items-center justify-content-center w-100">
						{{ getIdTemplate(item.expense.id) }}
					</div>
				</template>

				<template #head(description)="{ field }">
					<div id="description" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(description)="{ item }">
					<div class="d-flex align-items-center justify-content-center w-100">
						{{ getDescription(item.expense.description) }}
					</div>
				</template>

				<template #head(type)="{ field }">
					<div id="type" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(type)="{ item }">
					<div class="d-flex align-items-center justify-content-center w-100">
						{{ getTypeTemplate(item.expense) }}
					</div>
				</template>
				<template #cell(processed)="{ item }">
					<div class="d-flex align-items-center justify-content-center w-100">
						<span>
							<component v-if="item.expense.processed" :is="getLucideIcon('Check')" color="#00A09C" :size="18" :stroke-width="3" />
							<component v-else :is="getLucideIcon('X')" color="#225CBD" :size="18" :stroke-width="3" />
						</span>
					</div>
				</template>

				<template #head(amountTotal)="{ field }">
					<div id="amountTotal" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(amountTotal)="{ item }">
					<div class="d-flex align-items-center justify-content-end w-100">
						{{ getAmountTotal(item.expense.amountTotal) }}
					</div>
				</template>

				<template #head(kgCoTwo)="{ field }">
					<div id="kgCoTwo" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(kgCoTwo)="{ item }">
					<div class="d-flex align-items-center justify-content-end w-100">
						{{ getKgCoTwo(item.expense.kgCoTwo) }}
					</div>
				</template>

				<template #cell(details)="data">
					<button
						:id="`more-vertical-btn-${data.index}`"
						class="custom-btn-vertical"
						@click.prevent.stop="onOpenPopover(`more-vertical-btn-${data.index}`)"
					>
						<template>
							<component :is="getLucideIcon('MoreVertical')" :size="16" />
						</template>
					</button>
					<b-popover
						:show.sync="popoverVertical"
						v-if="targetId === `more-vertical-btn-${data.index}`"
						:target="`more-vertical-btn-${data.index}`"
						triggers="focus"
						custom-class="custom-class-btn-popover-vertical"
					>
						<div class="popover-item-btn-vertical" @click.prevent.stop="handleClickDetail(data.item.expense)">
							<div class="text-color-rhapsody-in-blue d-flex align-items-center">
								<component :is="getLucideIcon('Eye')" :size="16" />
								<div class="ml-2">{{ FormMSG(1259, 'View details') }}</div>
							</div>
						</div>
						<div
							class="popover-item-btn-vertical"
							@click.prevent.stop="handleClickCertifiedDigital(data.item.expense)"
							v-if="data.item.expense.validated === 8 && enableCertified"
						>
							<div class="text-color-rhapsody-in-blue d-flex align-items-center">
								<component :is="getLucideIcon('File')" :size="16" />
								<div class="ml-2">{{ FormMSG(1270, 'Certified digital expense sheet') }}</div>
							</div>
						</div>
					</b-popover>
				</template>

				<template #head(validated)="{ field }">
					<div id="validated" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #cell(validated)="data">
					<div class="wrap-status">
						<div :class="`status ${getStatusColor(data.item.expense.validated)}`" style="font-size: 0.7rem">
							{{ getLabelStatus(data.item.expense.validated) }}
						</div>
					</div>
				</template>

				<template #head(fullName)="{ field }">
					<div id="fullName" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
				<template #head(department)="{ field }">
					<div id="department" class="d-flex align-items-center flex-row no-select">
						<div class="d-flex justify-content-center flex-grow-1">{{ field.label }}</div>
						<div class="d-flex flex-column flex-grow-0">
							<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :color="getColorSortEd(field.key, 'asc')" :size="12" :stroke-width="6" />
							<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :color="getColorSortEd(field.key, 'desc')" :size="12" :stroke-width="6" />
						</div>
					</div>
				</template>
			</b-table>
			<div v-for="(item, index) of globalTrElements" class="popover-items" :key="index">
				<b-popover
					custom-class="popover-items"
					v-if="rowId === `row-${getIdText(item)}`"
					:show.sync="showContextMenu"
					:target="`row-${getIdText(item)}`"
				>
					<budget-context-menu
						:menuX="menuX"
						:menuY="menuY"
						@onClose="hideContextMenu"
						@on-mark-to-proccessed="handleToProccessed"
						@on-mark-to-un-proccessed="handleToUnProccessed"
						:isUnmarked="isUnmarked"
						:isMarked="isMarked"
					/>
				</b-popover>
			</div>
		</b-row>
		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="expense"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="expenseRerpotOnClosed"
		/>
		<!-- message dialog -->
		<info-message-modal :show-modal="stateError.show" @close="stateError.show = false">
			<div class="d-flex align-items-center text-info" style="gap: 10px">
				<component :is="getLucideIcon('Info')" :size="20" /><span>{{ stateError.msg }}</span>
			</div>
		</info-message-modal>
		<notification-api-to-odoo
			:open="openNotificationModalOdoo"
			:item="itemNotificationNotSendToApiOdoo"
			@action-modal-notification-api-odoo="handleActionModalApiOdoo"
		/>
		<!-- message dialog -->
	</div>
</template>

<script>
import InfoMessageModal from '@/components/Modals/InfoMessageModal';
import FilterBudgetAdvanced from '@/components/Budget/FilterBudgetAdvanced';
import CustomPagination from '@/components/Budget/CustomPagination';
import NotificationApiToOdoo from './NotificationApiToOdoo';
import BudgetContextMenu from './BudgetContextMenu';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { Search, X } from 'lucide-vue';
import moment from 'moment';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { mapActions } from 'vuex';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { getFileExtension } from '@/shared/helpers';
import _ from 'lodash';
import { expenseReport } from '@/cruds/export.crud';
import { getScripts } from '@/cruds/script.crud';
import filterInputSearch from '../users/filterInput.vue';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, FILTER_TYPE, ROUTE_NAME, VALIDATION_TYPE } from '@/shared/constants';
import { store } from '@/store';
import { changeStatusOfProcess } from '@/cruds/budget.crud';
import { expenseDematerializationReport, ChangeStatusExpensesProcess } from '@/cruds/expense.crud';
import axios from 'axios';

const statusClass = (validated) => {
	let value = 'not-submitted';
	if (validated === 1) {
		value = 'info';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 8) {
		value = 'validated';
	} else if (validated === 4 || validated === 16) {
		value = 'refused';
	}
	return value;
};

export default {
	name: 'ExpenseSheets',
	props: {
		budgetExpenseSheetData: { type: Array, required: true },
		totalRecords: { type: Number, required: 0 },
		tabIndex: { type: Number || undefined, required: false, default: 0 || undefined },
		isLoading: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	mixins: [languageMessages, GlobalMixin],
	components: {
		FilterBudgetAdvanced,
		Search,
		X,
		CustomPagination,
		ScriptExportModal,
		filterInputSearch,
		InfoMessageModal,
		BudgetContextMenu,
		NotificationApiToOdoo
	},
	data() {
		return {
			hv: 'dark',
			selectMode: 'single',
			selectedColor: 'primary',
			filter: '',
			expenseSheets: [],
			perPage: 100,
			haveActiveFilter: false,
			offset: 0,
			limit: 100,
			isScriptExpenseReportOpen: false,
			scripts: [],
			customReport: null,
			scriptType: 4,
			stateError: {
				show: false,
				msg: ''
			},
			initValueFilter: '',
			expenses: [],
			FILTER_TYPE,
			selectAll: false,
			processedIds: [],
			selectedRows: [],
			showContextMenu: false,
			tableClientRect: null,
			TYPE: {
				MULTIPLE: 'multiple',
				SINGLE: 'single'
			},
			ACTION_SELECT: {
				CTRL_KEY: 'ctrlKey',
				SHIT_KEY: 'shiftKey',
				CLICK_ONLY: 'click-only'
			},
			globalTrElements: [],
			menuY: 0,
			menuX: 0,
			rowEventListeners: [],
			rowId: '',
			startDrag: false,
			popoverVertical: false,
			targetId: '',
			isUnmarked: false,
			isMarked: false,
			sortEdElements: [],
			order: 'asc',
			orderBy: 'date',
			rendCurrency,
			rendKgCo2,
			itemNotificationNotSendToApiOdoo: [],
			openNotificationModalOdoo: false
		};
	},
	watch: {
		budgetExpenseSheetData: {
			async handler(newVal) {
				// console.log({ budgetExpenseSheetData: newVal });
				this.expenseSheets = _.cloneDeep(newVal);
				if (this.expenseSheets.length > 0) {
					await new Promise((resolve, reject) => {
						setTimeout(() => {
							this.initializeTables();
							resolve(true);
						}, 200);
					});
				}
				this.expenses = [];
				let tmpSelectProcessedAll = false;
				this.selectAll = false;
				this.processedIds = [];

				for (const expenseSheet of this.expenseSheets) {
					let item = expenseSheet.expense;
					item.fullName = item.user.name + ' ' + item.user.firstName;

					if (item.processed === true) {
						this.processedIds.push(item.id.toString());
					}

					if (tmpSelectProcessedAll === false) {
						if (item.processed === true) {
							this.selectAll = true;
							tmpSelectProcessedAll = true;
						}
					}

					this.expenses.push(item);
				}
			},
			immediate: true,
			deep: true
		},
		isLoading: {
			handler(newVal) {
				if (this.tabIndex === 2 && !newVal) {
					if (this.expenseSheets.length > 0) this.initializeTables();
				} else {
					this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
					this.selectedRows = [];
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		canMarkEntryAsProcessed() {
			return store.canMarkEntryAsProcessed();
		},
		mapExpenseSheets() {
			const array = this.expenseSheets.map((item) => item.expense);
			return this.stableSort(array, this.getComparator(this.order, this.orderBy)).map((item) => ({ expense: item }));
		},
		enableCertified() {
			return store.getCurrentProjectConfig().digitalCertifiedExpense;
		},
		bgFields() {
			let field = [
				{
					key: 'date',
					label: this.FormMSG(17, 'Date'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'id',
					label: this.FormMSG(218, 'ID'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(219, 'Description'),
					sortable: false,
					class: 'text-left'
				},
				{
					key: 'type',
					label: this.FormMSG(220, 'Type'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: this.FormMSG(221, 'Full Name'),
					formatter: (value, key, item) => {
						return `${item.expense.user.name} ${item.expense.user.firstName}`;
					},
					sortable: false,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'department',
					label: this.FormMSG(222, 'Department & Function'),
					formatter: (value, key, item) => {
						return `${item.expense.departmentName} - ${item.expense.functionName}`;
					},
					sortable: false,
					sortByFormatted: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(223, 'Status'),
					formatter: (value, key, item) => {
						return this.getLabelStatus(item.expense.validated);
					},
					sortable: false,
					sortByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(224, 'Total amount'),
					sortable: false,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: this.FormMSG(225, 'CO2'),
					sortable: false,
					class: 'text-right'
				},
				{
					key: 'processed',
					sortable: false,
					class: 'text-center w-90-px'
				},
				{
					key: 'details',
					label: this.FormMSG(226, 'Details'),
					sortable: false,
					class: 'text-center'
				}
			];
			/** on doit toujours garder la colonne processed */
			// if (this.canMarkEntryAsProcessed) {
			// 	field.push({
			// 		key: 'processed',
			// 		sortable: false,
			// 		class: 'text-center w-90-px'
			// 	});
			// }
			// field.push({
			// 	key: 'details',
			// 	label: this.FormMSG(226, 'Details'),
			// 	sortable: true,
			// 	class: 'text-center'
			// });
			return field;
		}
	},
	mounted() {
		if (this.$route.query.activeTabExpenseSheet) {
			this.initValueFilter = this.$route.query.filterSearch;
			this.filter = this.$route.query.filterSearch;
			if (this.mapExpenseSheets.length > 0) this.initializeTables();
		}
	},
	methods: {
		...mapActions({
			setFocusExpenseItem: 'expenses/setFocusExpenseItem',
			getCurExpense: 'expenses/getCurExpense'
		}),
		getColorSortEd(key, type) {
			const isSortEd = key.toString().toUpperCase() === this.orderBy.toString().toUpperCase();
			if (isSortEd) {
				if (this.order === type) {
					return '#FFFFFF';
				}
				return '#5B6164D6';
			}
			return '#5B6164D6';
		},
		dateFormat(_date) {
			if (!_.isNil(_date)) {
				return moment(_date).format('DD/MM/YYYY');
			}
		},
		getIdTemplate(id) {
			if (id) {
				return `#${id}`;
			}
		},
		getDescription(description) {
			if (description) {
				return description;
			}
		},
		getAmountTotal(amount) {
			if (amount) {
				return this.rendCurrency(amount);
			}
		},
		getKgCoTwo(value) {
			if (value) {
				return this.rendKgCo2(value);
			}
		},
		getTypeTemplate(item) {
			if (item) {
				const type = +item.type;
				return this.getLabelTypeExpense(type);
			}
			return '';
		},
		onOpenPopover(target) {
			this.targetId = '';
			this.popoverVertical = !this.popoverVertical;
			if (this.popoverVertical) {
				this.targetId = target;
			} else {
				this.targetId = '';
			}
			setTimeout(() => this.initializeTables(), 100);
		},
		getIdText(element) {
			if (element && element.childNodes[1]) {
				const elementId = +element.childNodes[1].innerText.split('#')[1] || 0;
				return elementId;
			} else {
				return 0;
			}
		},
		descendingComparator(a, b, orderBy) {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
			return 0;
		},
		getComparator(order, orderBy) {
			return order === 'desc' ? (a, b) => this.descendingComparator(a, b, orderBy) : (a, b) => -this.descendingComparator(a, b, orderBy);
		},
		stableSort(array, comparator) {
			const stabilizedThis = array.map((el, index) => [el, index]);
			stabilizedThis.sort((a, b) => {
				const order = comparator(a[0], b[0]);
				if (order !== 0) {
					return order;
				}
				return a[1] - b[1];
			});
			return stabilizedThis.map((el) => el[0]);
		},
		initWhenSort(e, orderBy) {
			e.preventDefault();
			if (this.selectedRows.length !== 0) {
				this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
				this.selectedRows = [];
			}
			const array = this.mapExpenseSheets.map((item) => item.expense);
			const isAsc = this.orderBy === orderBy && this.order === 'asc';
			this.orderBy = orderBy;
			this.order = isAsc ? 'desc' : 'asc';
			this.expenseSheets = this.stableSort(array, this.getComparator(this.order, this.orderBy)).map((item) => ({ expense: item }));
			this.initializeTables();
		},
		initActionSortEd(element) {
			if (this.sortEdElements.length !== 0) {
				this.sortEdElements.forEach(({ trChildren, initWhenSort }) => {
					trChildren.removeEventListener('click', initWhenSort);
				});
			}
			this.sortEdElements = [];
			const rowThElements = Array.from(element.children[0].rows[0].children);
			for (let i = 0; i < rowThElements.length; i++) {
				const trChildren = rowThElements[i];
				if (trChildren) {
					const textOfType = trChildren.children[0].id;
					if (textOfType.length !== 0) {
						const { key } = this.getTypeOfSort(textOfType);
						if (key) {
							const initWhenSort = (e) => this.initWhenSort(e, key);
							this.sortEdElements.push({ trChildren, initWhenSort });
						}
					}
				}
			}
			this.sortEdElements.map(({ trChildren, initWhenSort }) => trChildren.addEventListener('click', initWhenSort));
		},
		getTypeOfSort(text) {
			if (text) {
				const fields = this.bgFields;
				if (fields.length !== 0) {
					const isCheck = fields.find((item) => item.key === text);
					if (isCheck) {
						const _key = isCheck.key.toString().includes('expense.');
						if (_key) {
							const key = isCheck.key.toString().split('expense.')[1];
							return { key };
						}
						return { key: isCheck.key };
					}
					return { key: null };
				}
				return { key: null };
			}
		},
		initializeTables() {
			const element = document.getElementById('table-expense-sheets');
			if (element) {
				this.initActionSortEd(element);
				this.globalTrElements = [];
				const rows = element.children[1].children;
				this.globalTrElements = Array.from(rows);
				const tableClientRect = element.getBoundingClientRect();
				this.tableClientRect = tableClientRect;
				if (this.rowEventListeners) {
					this.rowEventListeners.forEach(({ row, clickHandler, contextMenuHandler, onMouseDown, onMouseUp, onMouseOver }) => {
						row.removeEventListener('click', clickHandler);
						row.removeEventListener('contextmenu', contextMenuHandler);
						row.addEventListener('mousedown', onMouseDown);
						row.addEventListener('mouseup', onMouseUp);
						row.addEventListener('mouseover', onMouseOver);
					});
				}
				this.rowEventListeners = [];
				Array.from(rows).forEach((row, index) => {
					let elementId = 0;
					const fieldId = row.childNodes[1];
					if (fieldId && fieldId.innerText) {
						elementId = +fieldId.innerText.split('#')[1] || 0;
					}
					row.setAttribute('id', `row-${elementId}`);
					row.classList.add('no-select');
					const clickHandler = (e) => this.clickRowTable(e, row, index);
					const contextMenuHandler = (e) => this.activeContextMenu(e, row, index);
					const onMouseDown = (e) => this.onMouseDown(e);
					const onMouseUp = (e) => this.onMouseUp(e);
					const onMouseOver = (e) => this.onMouseOver(e, row, index);
					row.addEventListener('click', clickHandler);
					row.addEventListener('contextmenu', contextMenuHandler);
					row.addEventListener('mousedown', onMouseDown);
					row.addEventListener('mouseup', onMouseUp);
					row.addEventListener('mouseover', onMouseOver);
					this.rowEventListeners.push({ row, clickHandler, contextMenuHandler, onMouseDown, onMouseUp });
				});
			}
		},
		hideContextMenu() {
			this.showContextMenu = false;
		},
		handleActionModalApiOdoo(e) {
			this.openNotificationModalOdoo = e;
			this.hideContextMenu();
			this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
			this.itemNotificationNotSendToApiOdoo = [];
			this.$emit('init-expense-sheet-data');
		},
		async handleToUnProccessed() {
			const txt = this.FormMSG(714, 'Please confirm that you want unproccessed');
			this.hideContextMenu();
			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(711, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(712, 'YES'),
					cancelTitle: this.FormMSG(713, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					else {
						const arraysId = [...new Set(this.selectedRows.filter((item) => item.processed === true).map((item) => +item.id))];
						const status = false;
						const result = await ChangeStatusExpensesProcess(arraysId, status)
							.then((res) => {
								if (res) {
									const expenseItemExist = res.flatMap((item) => item.expenseItem);
									if (expenseItemExist.length !== 0) {
										this.itemNotificationNotSendToApiOdoo = res;
									} else {
										this.itemNotificationNotSendToApiOdoo = [];
									}
									if (this.itemNotificationNotSendToApiOdoo.length !== 0) {
										this.hideContextMenu();
										this.openNotificationModalOdoo = true;
										return true;
									} else {
										this.openNotificationModalOdoo = false;
										this.createToastForMobile(
											this.FormMSG(700, 'Success'),
											this.FormMSG(701, 'Status changed to Unproccessed'),
											'',
											'success',
											true,
											'right'
										);
										this.hideContextMenu();
										this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
										this.$emit('init-expense-sheet-data');
										return true;
									}
								} else {
									this.hideContextMenu();
									this.createToastForMobile(this.FormMSG(703, 'Error'), this.FormMSG(704, 'Error request'), '', 'Error');
									this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
									this.$emit('init-expense-sheet-data');
									return false;
								}
							})
							.catch((_) => {
								this.hideContextMenu();
								this.createToastForMobile(this.FormMSG(703, 'Error'), this.FormMSG(704, 'Error request'), '', 'Error');
								this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
								this.$emit('init-expense-sheet-data');
								return false;
							});
						return result;
					}
				})
				.catch((err) => console.log(err));
		},
		async handleToProccessed() {
			const txt = this.FormMSG(710, 'Please confirm that you want proccessed');
			this.hideContextMenu();
			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(711, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(712, 'YES'),
					cancelTitle: this.FormMSG(713, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					else {
						const arraysId = [...new Set(this.selectedRows.filter((item) => item.processed === false).map((item) => +item.id))];
						const status = true;
						const result = await ChangeStatusExpensesProcess(arraysId, status)
							.then((res) => {
								if (res) {
									const expenseItemExist = res.flatMap((item) => item.expenseItem);
									if (expenseItemExist.length !== 0) {
										this.itemNotificationNotSendToApiOdoo = res;
									} else {
										this.itemNotificationNotSendToApiOdoo = [];
									}
									if (this.itemNotificationNotSendToApiOdoo.length !== 0) {
										this.hideContextMenu();
										this.openNotificationModalOdoo = true;
										return true;
									} else {
										this.openNotificationModalOdoo = false;
										this.createToastForMobile(
											this.FormMSG(700, 'Success'),
											this.FormMSG(702, 'Status changed to proccessed'),
											'',
											'success',
											true,
											'right'
										);
										this.hideContextMenu();
										this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
										this.$emit('init-expense-sheet-data');
										return true;
									}
								} else {
									this.hideContextMenu();
									this.createToastForMobile(this.FormMSG(703, 'Error'), this.FormMSG(704, 'Error request'), '', 'Error');
									this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
									this.$emit('init-expense-sheet-data');
									return false;
								}
							})
							.catch((_) => {
								this.hideContextMenu();
								this.createToastForMobile('Error', 'Error request', '', 'Error');
								this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
								this.$emit('init-expense-sheet-data');
								return false;
							});
						return result;
					}
				})
				.catch((err) => console.log(err));
		},
		getIndex(item) {
			if (item) {
				return this.mapExpenseSheets.findIndex((expSheet) => +expSheet.expense.id === +item.id);
			}
			return null;
		},
		isSelectedRow(element, _item, type, action) {
			if (!_.isNil(_item)) {
				const item = _item.expense;
				if (type === this.TYPE.MULTIPLE) {
					if (action === this.ACTION_SELECT.CTRL_KEY) {
						this.selectedRows = this.selectedRows.map((item) => ({ ...item, isShift: false }));
						const data = this.selectedRows;
						const _index = data.findIndex((_item) => +_item.id === +item.id);
						if (_index === -1) {
							element.classList.add('selected-class');
							this.selectedRows = [...this.selectedRows, { ...item, isShift: false, element }];
						} else {
							element.classList.remove('selected-class');
							this.selectedRows.splice(_index, 1);
						}
					}
					if (action === this.ACTION_SELECT.SHIT_KEY) {
						if (this.selectedRows.length === 0) {
							this.selectedRows = [{ ...item, isShift: false, element }];
							const isShiftElementLastActive = this.selectedRows;
							isShiftElementLastActive.forEach((item) => {
								item.element.classList.add('selected-class');
							});
						} else {
							const isShiftElementLastActive = this.selectedRows.filter((item) => item.isShift === true);
							isShiftElementLastActive.forEach((item) => {
								item.element.classList.remove('selected-class');
							});
							this.selectedRows = this.selectedRows.filter((item) => item.isShift === false);
							const size = this.selectedRows.length - 1;
							const itemLast = this.selectedRows[size];
							const startIndex = this.getIndex(itemLast);
							const lastIndex = this.getIndex(item);
							const arrayIndex = [startIndex, lastIndex];
							const max = Math.max(...arrayIndex, 0) + 1;
							const min = Math.min(...arrayIndex);
							const getElement = (id) => {
								const _index = this.mapExpenseSheets.findIndex((expSheet) => +expSheet.expense.id === +id);
								let element = null;
								if (_index !== -1) {
									element = this.globalTrElements[_index];
									return element;
								}
								return element;
							};
							const data = this.mapExpenseSheets
								.slice(min, max)
								.map((item) => item.expense)
								.map((item) => {
									const element = getElement(item.id);
									if (element) {
										element.classList.add('selected-class');
									}
									return { ...item, element, isShift: true };
								});
							this.selectedRows = [...this.selectedRows, ...data];
						}
					}
				}
				if (type === this.TYPE.SINGLE) {
					this.selectedRows = this.selectedRows.map((item) => ({ ...item, isShift: false }));
					const data = this.selectedRows;
					const _index = data.findIndex((_item) => +_item.id === +item.id);
					if (_index === -1) {
						this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
						element.classList.add('selected-class');
						this.selectedRows = [{ ...item, isShift: false, element }];
					} else {
						if (data.length > 1) {
							this.selectedRows.map((item) => item.element.classList.remove('selected-class'));
							element.classList.add('selected-class');
							this.selectedRows = [{ ...item, isShift: false, element }];
						} else {
							element.classList.remove('selected-class');
							this.selectedRows.splice(_index, 1);
						}
					}
				}
			}
			// remove not validated expense from selections.
			this.selectedRows.forEach((item) => {
				if (item.validated !== VALIDATION_TYPE.VALIDATED_LEVEL_2) {
					item.element.classList.remove('selected-class');
				}
			});
			this.selectedRows = this.selectedRows.filter((item) => item.validated === VALIDATION_TYPE.VALIDATED_LEVEL_2);
			return this.selectedRows;
		},
		activeContextMenu(event, row, index) {
			event.preventDefault();
			if (this.selectedRows.length !== 0 && this.canMarkEntryAsProcessed) {
				const { width } = this.tableClientRect;
				const screenWidth = window.innerWidth;
				const screenHeight = window.innerHeight;
				const spaceWidth = screenWidth - width - 100;
				const menuWidth = 350;
				const menuHeight = 200;
				const clickX = event.clientX;
				const clickY = event.clientY;
				const maxTop = screenHeight - menuHeight;
				const maxLeft = screenWidth - menuWidth;
				this.menuY = clickY > maxTop ? -100 : 0;
				this.menuX = clickX > maxLeft ? clickX - spaceWidth - menuWidth : Math.max(clickX - spaceWidth, 0);
				const elementId = +row.childNodes[1].innerText.split('#')[1] || 0;
				this.rowId = `row-${elementId}`;
				this.showContextMenu = true;
			}
		},
		onMouseDown(event) {
			event.preventDefault();
			this.startDrag = true;
		},
		onMouseOver(event, element, index) {
			event.preventDefault();
			if (this.startDrag) {
				const elementId = +element.childNodes[1].innerText.split('#')[1] || 0;
				const item = this.mapExpenseSheets.find((x) => parseInt(x.expense.id, 10) === elementId);
				const type = this.TYPE.MULTIPLE;
				const action = this.ACTION_SELECT.SHIT_KEY;
				const result = this.isSelectedRow(element, item, type, action);
				const { isUnmarked, isMarked } = this.isCheckStatusToShowContextMenuItem(result);
				this.isUnmarked = isUnmarked;
				this.isMarked = isMarked;
				return result;
			}
		},
		onMouseUp(event) {
			event.preventDefault();
			this.startDrag = false;
		},
		getStatus(boolean) {
			if (boolean) {
				return this.FormMSG(25, 'Submitted');
			} else {
				return this.FormMSG(26, 'Not submitted');
			}
		},
		isCheckStatusToShowContextMenuItem(data) {
			let isUnmarked = false;
			let isMarked = false;
			if (data.length === 0) {
				isMarked = true;
				isUnmarked = false;
				// const isProcessed = data[0].processed; /** data.length === 0 donc il n'y a plus rien dans Data ( data[0].processed return undefined) */
				// if (isProcessed) {
				// 	isMarked = false;
				// 	isUnmarked = true;
				// } else {
				// 	isMarked = true;
				// 	isUnmarked = false;
				// }
			} else {
				const isAllProcessed = data.every((item) => item.processed === true);
				const isAllUnProcessed = data.every((item) => item.processed === false);
				if (isAllProcessed) {
					isMarked = false;
					isUnmarked = true;
				} else if (isAllUnProcessed) {
					isMarked = true;
					isUnmarked = false;
				} else {
					isMarked = true;
					isUnmarked = true;
				}
			}
			return { isUnmarked, isMarked };
		},
		clickRowTable(event, element, index) {
			event.preventDefault();
			this.menuX = 0;
			this.menuY = 0;
			this.showContextMenu = false;
			this.rowId = '';
			const ctrlKey = event.ctrlKey;
			const shiftKey = event.shiftKey;
			const elementId = +element.childNodes[1].innerText.split('#')[1] || 0;
			const item = this.mapExpenseSheets.find((x) => parseInt(x.expense.id, 10) === elementId);
			this.initializeTables();
			if (ctrlKey) {
				const type = this.TYPE.MULTIPLE;
				const action = this.ACTION_SELECT.CTRL_KEY;
				const result = this.isSelectedRow(element, item, type, action);
				const { isUnmarked, isMarked } = this.isCheckStatusToShowContextMenuItem(result);
				this.isUnmarked = isUnmarked;
				this.isMarked = isMarked;
				return result;
			} else if (shiftKey) {
				const type = this.TYPE.MULTIPLE;
				const action = this.ACTION_SELECT.SHIT_KEY;
				const result = this.isSelectedRow(element, item, type, action);
				const { isUnmarked, isMarked } = this.isCheckStatusToShowContextMenuItem(result);
				this.isUnmarked = isUnmarked;
				this.isMarked = isMarked;
				return result;
			} else {
				const type = this.TYPE.SINGLE;
				const action = this.ACTION_SELECT.CLICK_ONLY;
				const result = this.isSelectedRow(element, item, type, action);
				const { isUnmarked, isMarked } = this.isCheckStatusToShowContextMenuItem(result);
				this.isUnmarked = isUnmarked;
				this.isMarked = isMarked;
				return result;
			}
		},
		handleSelcted(data) {
			this.selectedRows = data;
		},
		handleContextMenu() {
			this.showContextMenu = !this.showContextMenu;
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		getTypeText(type) {
			return this.GetTextFromMenuNumberAndMenuValue(1003, type);
		},
		getSubCategoryText(id) {
			return this.GetTextFromMenuNumberAndMenuValue(905, id);
		},
		handleDismissedError() {
			this.stateError = {
				show: false,
				msg: ''
			};
		},
		async handleClickCertifiedDigital(item) {
			try {
				this.$emit('budget-expense-sheets:generate-certified-start');
				const resultFile = await expenseDematerializationReport(item.id, false);

				const url = process.env.VUE_APP_GQL + '/images/' + resultFile;
				axios.get(url, { responseType: 'blob' }).then((response) => {
					const link = window.URL.createObjectURL(new Blob([response.data]));
					const anchor = document.createElement('a');
					anchor.setAttribute('href', link);
					anchor.setAttribute('target', '_blank');
					anchor.setAttribute('download', resultFile);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);

					this.$emit('budget-expense-sheets:generate-certified-end');
				});
			} catch (e) {
				this.$emit('budget-expense-sheets:generate-certified-end');
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
			}
		},
		activeFilter(keyword) {
			this.haveActiveFilter = true;
			this.filter = keyword;
			this.$emit('activate-filter-by-keyword', keyword);
		},
		onHandleInitFilter() {
			this.filter = '';
			this.haveActiveFilter = false;
			this.$emit('activate-filter-by-keyword', null);
		},
		async handleChangeProcess(payload, item, index) {
			await changeStatusOfProcess(+item.expense.id, 1, payload);

			if (payload === false) {
				const idx = this.processedIds.findIndex((id) => id === item.expense.id.toString());
				if (idx !== -1) {
					this.processedIds.splice(idx, 1);
				}
			}

			this.selectAll = this.processedIds.length > 0;
		},
		handleClickDetail(expense) {
			const item = {
				...expense,
				validatedClass: this.validatedText(expense.validated),
				statusClass: statusClass(expense.validated),
				typeString: this.getTypeText(expense.type),
				validatedStatus: this.validatedText(expense.validated),
				subCategoryName: this.getSubCategoryText(expense.subCategory)
			};
			this.getCurExpense(parseInt(expense.id, 10));
			this.setFocusExpenseItem(item);
			this.$router.replace({
				name: ROUTE_NAME.budgetExpenseDetail,
				query: {
					fromBudget: true,
					filterSearch: this.filter
				},
				params: {
					id: expense.id
				}
			});
		},
		handleClickLoadMore({ offset, limit, perPage }) {
			this.offset = offset;
			this.limit = limit;
			this.perPage = perPage;

			this.$emit('budget-expense-sheets:load-more', {
				offset,
				limit,
				perPage
			});
		},
		getLabelTypeExpense(value) {
			let name = '';
			const labelTypes = [
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
			const types = labelTypes.filter((lT) => lT.value === value);
			if (types.length > 0) {
				for (const typeName of types) {
					return (name = typeName.text.toUpperCase());
				}
			}
			return name;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		getStatusColor(validated) {
			let retval = 'not-submitted';
			if (validated == 1) {
				retval = 'info';
			} else if (validated == 2) {
				retval = 'pending';
			} else if (validated == 8) {
				retval = 'validated';
			} else if (validated == 4 || validated == 16) {
				retval = 'refused';
			}
			return retval;
		},
		emitLoading(value) {
			this.$emit('budget-gl:loading', value);
		},
		async handleFilterBudgetAdvancedClickSelection(oPayload) {
			this.emitLoading(true);
			const payload = _.omit(oPayload, this.removeFromReportKeys);

			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.scriptType, true);

			let script = { id: 0, fileName: null, ext: 'xlsx' };

			if (this.scripts.length > 1) {
				this.customReport = payload;
				this.isScriptExpenseReportOpen = true;
				this.emitLoading(false);
			} else {
				this.customReport = null;

				if (this.scripts.length === 1) {
					script = {
						id: this.scripts[0].id,
						fileName: this.scripts[0].customExportFileName,
						ext: this.getExtensionScript(
							OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
							this.scripts[0].customExtension,
							getFileExtension(this.scripts[0].fileName)
						)
					};

					let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

					await expenseReport(payload, script.id)
						.then((result) => {
							this.forceDownload(result, fileName + '.' + script.ext);
						})
						.catch((e) => {
							console.error(e);
							this.emitLoading(false);
						})
						.finally(() => {
							this.emitLoading(false);
						});
				} else {
					this.createToastForMobile(
						this.FormMSG(330, 'Warning'),
						this.FormMSG(331, 'Please create at least one script template to execute correctly this export.'),
						'',
						'warning'
					);
					this.emitLoading(false);
				}
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.emitLoading(false);
		},
		handleLoading(payload) {
			if (payload) {
				this.emitLoading(false);
			}
		},
		handleInputSelectAll(payload) {
			this.expenseSheets = this.mapExpenseSheets.map(async (expenseSheet) => {
				expenseSheet.expense.processed = payload;

				await changeStatusOfProcess(+expenseSheet.expense.id, 1, payload);

				return expenseSheet;
			});
		}
	}
};
</script>

<style lang="scss">
#processed-all .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #fff;
	background-color: #00a09c;
}
.selected-class {
	background-color: #b8e4e3 !important;
}
.no-select {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.popover-items {
	background: transparent !important;
	border: none !important;
	.arrow {
		display: none;
	}
}
.custom-btn-vertical {
	border: none;
	background-color: transparent;
}
.custom-class-btn-popover-vertical {
	.popover-item-btn-vertical {
		display: flex;
		border-radius: 2px;
		padding: 2px;
		align-items: center;
		justify-content: start;
		height: 34px;
		font-weight: 600;
		&:hover {
			cursor: pointer;
			background-color: hsl(206, 96%, 90%);
		}
		div {
			margin-left: 4px;
			margin-right: 4px;
		}
	}
}
thead tr {
	cursor: pointer;
}
/*.processed-status {
	display: flex;
	border: 2px solid #00a09c;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;
}
.unprocessed-status {
	display: flex;
	border: 2px solid #225cbd;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;
}*/
</style>
