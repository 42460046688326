var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-content-center flex-column position-absolute custom-context-menu",
      style: { left: `${_vm.menuX}px`, top: `${_vm.menuY}px` },
      attrs: { id: "context-menu" },
    },
    [
      _c("section", { staticClass: "section-item" }, [
        _vm.isMarked
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center gap-4 p-2 item",
                on: { click: _vm.onMarkToProcessed },
              },
              [
                _c(
                  "div",
                  { staticClass: "w-15" },
                  [
                    _c(_vm.getLucideIcon("Check"), {
                      tag: "component",
                      attrs: { color: "#00A09C", size: 18, "stroke-width": 3 },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "w-85" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Mark as processed"))),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.isUnmarked
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center gap-4 p-2 item",
                on: { click: _vm.onUnMarkToProcessed },
              },
              [
                _c(
                  "div",
                  { staticClass: "w-15" },
                  [
                    _c(_vm.getLucideIcon("X"), {
                      tag: "component",
                      attrs: { color: "#225CBD", size: 18, "stroke-width": 3 },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "w-85" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.FormMSG(2, "Unmark as processed"))),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "w-100" }, [
          _c("button", { on: { click: _vm.onClose } }, [
            _vm._v(_vm._s(_vm.FormMSG(3, "Close"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }