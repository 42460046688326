<template>
	<b-modal v-model="open" no-close-on-esc no-close-on-backdrop hide-header-close :title="`${FormMSG(14, 'Informations')}`" size="xl">
		<template #modal-header>
			<section class="d-flex flex-row align-items-center w-100">
				<div class="d-flex flex-row align-items-center justify-content-start fs-14 w-25 gap-1">
					<div class="status-header">
						<div><component :is="getLucideIcon(ICONS.ALERT_TRIANGLE.name)" color="#ebe78a" :size="20" /></div>
						<div class="mt-1">
							<span> {{ FormMSG(15, 'Warning') }}</span>
						</div>
					</div>
				</div>
				<div class="d-flex align-items-center justify-content-end fs-14 w-75">
					<span class="border-text">{{ FormMSG(16, 'These expense are not sent, the flags or categories are not complete') }}</span>
				</div>
			</section>
		</template>
		<div id="table" class="d-flex flex-column align-items-center justify-content-center w-100 table-content">
			<div class="d-flex flex-row w-100 row">
				<div class="d-flex w-12">{{ FormMSG(6, 'Date') }}</div>
				<div class="d-flex w-6">{{ FormMSG(7, 'Id') }}</div>
				<div class="d-flex w-43">{{ FormMSG(8, 'Description') }}</div>
				<div class="d-flex w-10">{{ FormMSG(9, 'Type') }}</div>
				<div class="d-flex w-12">{{ FormMSG(11, 'Status') }}</div>
				<div class="d-flex w-10">{{ FormMSG(12, 'Total amount') }}</div>
				<div class="d-flex w-7">{{ FormMSG(13, 'CO2') }}</div>
			</div>
			<div v-for="(_item, index) of mapItem" :key="index" class="d-flex flex-row w-100 cell">
				<section class="d-flex flex-column w-100 gap-4">
					<section class="d-flex flex-row w-100">
						<div class="d-flex w-12 border-cell">
							<div class="d-flex flex-row gap-1 align-items-center">
								<div>
									<button v-if="!mapActiveShowChildren(_item)" @click="handleActiveChild(_item)">+</button>
									<button v-else-if="mapActiveShowChildren(_item)" @click="handleActiveChild(_item)">-</button>
								</div>
								<div>{{ dateFormat(_item.date) }}</div>
							</div>
						</div>
						<div class="d-flex w-6 border-cell">{{ getIdTemplate(_item.id) }}</div>
						<div class="d-flex w-43 border-cell">{{ getDescription(_item.description) }}</div>
						<div class="d-flex w-10 border-cell">{{ getTypeTemplate(_item) }}</div>
						<div class="d-flex w-12 border-cell">
							<div class="wrap-status">
								<div :class="`status ${getStatusColor(_item.validated)}`" style="font-size: 0.7rem">
									{{ getLabelStatus(_item.validated) }}
								</div>
							</div>
						</div>
						<div class="d-flex w-10 border-cell">{{ getAmountTotal(_item.amountTotal) }}</div>
						<div class="d-flex w-7 border-cell">{{ getKgCoTwo(_item.kgCoTwo) }}</div>
					</section>
					<section class="d-flex flex-column w-100 cell-item-child" v-if="mapActiveShowChildren(_item)">
						<div class="d-flex flex-row w-100" v-for="(expItem, expIndex) of mapExpenseItem(_item)" :key="expIndex">
							<div class="d-flex align-items-center justify-content-end w-id">{{ FormMSG(100, 'ID') }}: {{ expItem.id }}</div>
							<div class="d-flex flex-column ml-4 w-100">
								<table>
									<thead>
										<tr class="bg-primary">
											<th>{{ FormMSG(101, 'Name') }}</th>
											<th>{{ FormMSG(102, 'Short') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(mssFlag, mssIndex) of expItem.missingProjectFlag" :key="mssIndex">
											<td style="width: 30%">
												<u class="fw-700">{{ FormMSG(104, 'Flag') }}</u>
												{{ ' : ' + mssFlag.name }}
											</td>
											<td style="width: 30%">{{ mssFlag.short }}</td>
										</tr>
										<tr v-if="expItem.category === 0">
											<td colspan="2">
												<u class="fw-700">{{ FormMSG(103, 'Category') }}</u>
												{{ ' : ' + expItem.categoryName }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</section>
				</section>
			</div>
		</div>
		<template #modal-footer>
			<b-button size="sm" variant="light" @click="closeModal()"> {{ FormMSG(2, 'Close') }} </b-button>
		</template>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { rendCurrency, rendKgCo2 } from '~helpers';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'NotificationApiToOdoo',
	mixins: [languageMessages, GlobalMixin],
	components: {},
	data: () => ({
		rendCurrency,
		rendKgCo2,
		showChildren: false,
		targetRowActiveChild: '',
		activeArrayChildren: []
	}),
	props: {
		open: {
			type: Boolean,
			required: false,
			default: false
		},
		item: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		mapItem() {
			if (this.item) {
				return this.item;
			}
			return [];
		}
	},
	watch: {
		item: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		mapExpenseItem(_item) {
			if (_item) {
				return _item.expenseItem;
			}
			return [];
		},
		mapActiveShowChildren(_item) {
			if (_item) {
				const index = +_item.id;
				const isActive = this.activeArrayChildren.find((item) => item.index === index);
				if (isActive) {
					return true;
				} else {
					return false;
				}
			}
		},
		handleActiveChild(_item) {
			if (_item) {
				this.showChildren = !this.showChildren;
				const index = +_item.id;
				const isActive = this.activeArrayChildren.find((item) => +item.index === +index);
				if (isActive) {
					const _index = this.activeArrayChildren.findIndex((item) => item.index === isActive.index);
					if (_index !== -1) {
						this.activeArrayChildren.splice(_index, 1);
					}
					return this.mapActiveShowChildren(_item);
				} else {
					this.activeArrayChildren = [...this.activeArrayChildren, { index }];
					return this.mapActiveShowChildren(_item);
				}
			}
		},
		closeModal() {
			this.showChildren = false;
			this.targetRowActiveChild = '';
			this.$emit('action-modal-notification-api-odoo', false);
		},
		dateFormat(_date) {
			if (!_.isNil(_date)) {
				return moment(_date).format('DD/MM/YYYY');
			}
		},
		getIdTemplate(id) {
			if (id) {
				return `#${id}`;
			}
		},
		getDescription(description) {
			if (description) {
				return description;
			}
		},
		getAmountTotal(amount) {
			if (amount) {
				return this.rendCurrency(amount);
			}
		},
		getKgCoTwo(value) {
			if (value) {
				return this.rendKgCo2(value);
			}
		},
		getTypeTemplate(item) {
			if (item) {
				const type = item.type;
				return this.getLabelTypeExpense(type);
			}
		},
		getStatusColor(validated) {
			let retval = 'not-submitted';
			if (validated == 1) {
				retval = 'info';
			} else if (validated == 2) {
				retval = 'pending';
			} else if (validated == 8) {
				retval = 'validated';
			} else if (validated == 4 || validated == 16) {
				retval = 'refused';
			}
			return retval;
		},
		getDepFunc(item) {
			return `${item.departmentName} - ${item.functionName}`;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		getLabelTypeExpense(value) {
			let name = '';
			const labelTypes = [
				{ value: 0, text: this.FormMSG(3, 'Ticket') },
				{ value: 1, text: this.FormMSG(4, 'Invoice') },
				{ value: 2, text: this.FormMSG(5, 'Travel') }
			];
			const types = labelTypes.filter((lT) => lT.value === value);
			if (types.length > 0) {
				for (const typeName of types) {
					return (name = typeName.text.toUpperCase());
				}
			}
			console.log(name);
			return name;
		}
	}
};
</script>
<style scoped lang="scss">
$bg-primary: #00a09c;
.status-header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	background-color: #ffc107;
	border-radius: 4px;
	font-weight: 700;
	width: 110px;
	gap: 4px;
	color: #536476;
}
.border-text {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #b8b9ba;
	padding: 6px;
	border-radius: 4px;
}
.w-5 {
	width: 5%;
}
.w-7 {
	width: 7%;
}
.w-6 {
	width: 6%;
}
.w-10 {
	width: 10%;
}
.w-12 {
	width: 12%;
}
.w-14 {
	width: 12%;
}
.w-43 {
	width: 43%;
}
.w-20 {
	width: 20%;
}
.w-25 {
	width: 25%;
}
.w-id {
	width: 124px;
}
.gap-4 {
	gap: 4px;
}
thead {
	height: 30px;
	th {
		width: 50%;
		padding: 6px;
	}
}
tbody {
	height: 30px;
	td {
		width: 50%;
		padding: 6px;
	}
}
.table-content {
	width: 100%;
	.gap-1 {
		gap: 8px;
		button {
			display: flex;
			border-radius: 3px;
			justify-content: center;
			align-items: center;
			border: 1px solid black;
			height: 20px;
		}
	}
	.row {
		border: 1px solid #b8b9ba !important;
		div {
			display: flex;
			letter-spacing: 1px;
			color: #ffffff;
			border: 1px solid #b8b9ba;
			padding: 4px;
			background-color: $bg-primary;
			height: 40px;
			align-items: center;
		}
	}
	.cell {
		display: flex;
		cursor: pointer;
		&:hover {
			background-color: hsla(206, 96%, 90%, 0.5);
			.cell-item-child {
				background-color: #ffffff;
			}
		}
		.border-cell {
			letter-spacing: 1px;
			border: 1px solid #b8b9ba;
			justify-content: center;
			align-items: center;
			height: 50px;
			padding-left: 6px;
			padding-right: 6px;
			.wrap-status {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
		}
	}
}
</style>
