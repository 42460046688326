var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-layout form px-0" },
    [
      _c("filter-budget-advanced", {
        attrs: {
          "show-user-selector": false,
          "label-expense-details": _vm.FormMSG(169, "Expense details"),
          "use-department-for-expense": "",
          "hide-supplier-section": "",
          "hide-expense-details-department": "",
          "hide-expense-details-category": "",
          "hide-co-two-detail": "",
          "hide-process-filter": false,
          "filter-type": _vm.FILTER_TYPE.BUDGET_EXPENSE_SHEETS,
          "hide-flag-filter": false,
        },
        on: {
          "filter-budget-advanced:click-selection":
            _vm.handleFilterBudgetAdvancedClickSelection,
        },
      }),
      _c(
        "b-row",
        { staticClass: "mt-3 hide-on-tablet" },
        [
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-form-group",
                { staticClass: "mb-0" },
                [
                  _vm.$screen.width >= 992
                    ? _c(
                        "b-input-group",
                        [
                          _c("filterInputSearch", {
                            attrs: {
                              id: "expense-sheet",
                              "init-value": _vm.initValueFilter,
                            },
                            on: {
                              "filtered-users": _vm.activeFilter,
                              "handle-init-filter": _vm.onHandleInitFilter,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "7" } },
            [
              !_vm.haveActiveFilter
                ? _c("custom-pagination", {
                    attrs: {
                      "total-records": _vm.totalRecords,
                      "initial-perpage": _vm.perPage,
                      "initial-offset": _vm.offset,
                      "initial-limit": _vm.limit,
                      "filter-type": _vm.FILTER_TYPE.BUDGET_EXPENSE_SHEETS,
                    },
                    on: {
                      "custom-pagination:previous": _vm.handleClickLoadMore,
                      "custom-pagination:forward": _vm.handleClickLoadMore,
                      "custom-pagination:change-per-page":
                        _vm.handleClickLoadMore,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3 ml-1" },
        [
          !_vm.$screen.md
            ? _c(
                "CardListBuilder",
                {
                  staticClass: "hide-on-desktop",
                  staticStyle: { width: "100%" },
                  attrs: {
                    items: _vm.expenses,
                    filter: _vm.filter,
                    fields: _vm.bgFieldsMobile,
                  },
                },
                [
                  _c("template", { slot: "actions" }, [
                    _c("div", { staticClass: "mt-2" }),
                  ]),
                ],
                2
              )
            : _c("b-table", {
                staticStyle: { width: "100%", "font-weight": "500" },
                attrs: {
                  responsive: "sm",
                  filter: _vm.filter,
                  items: _vm.mapExpenseSheets,
                  fields: _vm.bgFields,
                  "sticky-header": "700px",
                  id: "table-expense-sheets",
                  "head-variant": _vm.hv,
                  hover: true,
                  bordered: "",
                  small: "",
                  "show-empty": "",
                  selectable: false,
                  "tbody-tr-class": "timeline-cursor",
                  "empty-text": _vm.FormMSG(265, "No expense sheets found"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(date)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "date" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(date)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center w-100",
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.dateFormat(item.expense.date))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(id)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "id" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(id)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.getIdTemplate(item.expense.id)) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(description)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "description" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(description)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.getDescription(item.expense.description)
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(type)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "type" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(type)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.getTypeTemplate(item.expense)) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(processed)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center w-100",
                          },
                          [
                            _c(
                              "span",
                              [
                                item.expense.processed
                                  ? _c(_vm.getLucideIcon("Check"), {
                                      tag: "component",
                                      attrs: {
                                        color: "#00A09C",
                                        size: 18,
                                        "stroke-width": 3,
                                      },
                                    })
                                  : _c(_vm.getLucideIcon("X"), {
                                      tag: "component",
                                      attrs: {
                                        color: "#225CBD",
                                        size: 18,
                                        "stroke-width": 3,
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(amountTotal)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "amountTotal" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(amountTotal)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.getAmountTotal(item.expense.amountTotal)
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(kgCoTwo)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "kgCoTwo" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(kgCoTwo)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end w-100",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.getKgCoTwo(item.expense.kgCoTwo)) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(details)",
                    fn: function (data) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "custom-btn-vertical",
                            attrs: { id: `more-vertical-btn-${data.index}` },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.onOpenPopover(
                                  `more-vertical-btn-${data.index}`
                                )
                              },
                            },
                          },
                          [
                            [
                              _c(_vm.getLucideIcon("MoreVertical"), {
                                tag: "component",
                                attrs: { size: 16 },
                              }),
                            ],
                          ],
                          2
                        ),
                        _vm.targetId === `more-vertical-btn-${data.index}`
                          ? _c(
                              "b-popover",
                              {
                                attrs: {
                                  show: _vm.popoverVertical,
                                  target: `more-vertical-btn-${data.index}`,
                                  triggers: "focus",
                                  "custom-class":
                                    "custom-class-btn-popover-vertical",
                                },
                                on: {
                                  "update:show": function ($event) {
                                    _vm.popoverVertical = $event
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "popover-item-btn-vertical",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.handleClickDetail(
                                          data.item.expense
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-color-rhapsody-in-blue d-flex align-items-center",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Eye"), {
                                          tag: "component",
                                          attrs: { size: 16 },
                                        }),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(1259, "View details")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                data.item.expense.validated === 8 &&
                                _vm.enableCertified
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "popover-item-btn-vertical",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.handleClickCertifiedDigital(
                                              data.item.expense
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-color-rhapsody-in-blue d-flex align-items-center",
                                          },
                                          [
                                            _c(_vm.getLucideIcon("File"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                            _c("div", { staticClass: "ml-2" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1270,
                                                    "Certified digital expense sheet"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "head(validated)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "validated" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(validated)",
                    fn: function (data) {
                      return [
                        _c("div", { staticClass: "wrap-status" }, [
                          _c(
                            "div",
                            {
                              class: `status ${_vm.getStatusColor(
                                data.item.expense.validated
                              )}`,
                              staticStyle: { "font-size": "0.7rem" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.getLabelStatus(
                                      data.item.expense.validated
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "head(fullName)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "fullName" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "head(department)",
                    fn: function ({ field }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center flex-row no-select",
                            attrs: { id: "department" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center flex-grow-1",
                              },
                              [_vm._v(_vm._s(field.label))]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-grow-0" },
                              [
                                _c(
                                  _vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "asc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                                _c(
                                  _vm.getLucideIcon(
                                    _vm.ICONS.CHEVRON_DOWN.name
                                  ),
                                  {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.getColorSortEd(
                                        field.key,
                                        "desc"
                                      ),
                                      size: 12,
                                      "stroke-width": 6,
                                    },
                                  }
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
          _vm._l(_vm.globalTrElements, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "popover-items" },
              [
                _vm.rowId === `row-${_vm.getIdText(item)}`
                  ? _c(
                      "b-popover",
                      {
                        attrs: {
                          "custom-class": "popover-items",
                          show: _vm.showContextMenu,
                          target: `row-${_vm.getIdText(item)}`,
                        },
                        on: {
                          "update:show": function ($event) {
                            _vm.showContextMenu = $event
                          },
                        },
                      },
                      [
                        _c("budget-context-menu", {
                          attrs: {
                            menuX: _vm.menuX,
                            menuY: _vm.menuY,
                            isUnmarked: _vm.isUnmarked,
                            isMarked: _vm.isMarked,
                          },
                          on: {
                            onClose: _vm.hideContextMenu,
                            "on-mark-to-proccessed": _vm.handleToProccessed,
                            "on-mark-to-un-proccessed":
                              _vm.handleToUnProccessed,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("script-export-modal", {
        attrs: {
          items: _vm.scripts,
          custom: _vm.customReport,
          "export-type": "expense",
        },
        on: {
          "script-export-modal:loading": _vm.handleLoading,
          "script-export-modal:closed": _vm.expenseRerpotOnClosed,
        },
        model: {
          value: _vm.isScriptExpenseReportOpen,
          callback: function ($$v) {
            _vm.isScriptExpenseReportOpen = $$v
          },
          expression: "isScriptExpenseReportOpen",
        },
      }),
      _c(
        "info-message-modal",
        {
          attrs: { "show-modal": _vm.stateError.show },
          on: {
            close: function ($event) {
              _vm.stateError.show = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center text-info",
              staticStyle: { gap: "10px" },
            },
            [
              _c(_vm.getLucideIcon("Info"), {
                tag: "component",
                attrs: { size: 20 },
              }),
              _c("span", [_vm._v(_vm._s(_vm.stateError.msg))]),
            ],
            1
          ),
        ]
      ),
      _c("notification-api-to-odoo", {
        attrs: {
          open: _vm.openNotificationModalOdoo,
          item: _vm.itemNotificationNotSendToApiOdoo,
        },
        on: {
          "action-modal-notification-api-odoo": _vm.handleActionModalApiOdoo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }